import { createAsyncThunk } from '@reduxjs/toolkit'
import carrierService, { FindCarriersQuery, FindCarriersResponse } from '../../../services/carrier'

export const getCarriers = createAsyncThunk<FindCarriersResponse, FindCarriersQuery>(
  'carrier/find',
  carrierService.getCarriers
)

export const getCarriersIntegrations = createAsyncThunk<FindCarriersResponse, FindCarriersQuery>(
  'carrier/find-integration',
  carrierService.getCarriersIntegration
)

export const carriersBulkUpload = createAsyncThunk('carrier/bulkupload', carrierService.bulkUpload)

export const createOrRelateCarrier = createAsyncThunk(
  'carrier/create-or-relate',
  carrierService.createOrRelateCarrier
)

export const unrelateCarrier = createAsyncThunk(
  'carrier/:id/unrelate',
  carrierService.unrelateCarrier
)

export const updateCarrier = createAsyncThunk('carrier/:id/update', carrierService.updateCarrier)
