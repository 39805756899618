import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Company } from 'models'
import { TerminalCarrierIntegration } from 'models/terminal-carrier-integration.model'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  getAutomationSwitch,
  createAutomationSwitch
} from './automation-switch.actions'

export type automationSwitchState = {
  automationSwitchs: TerminalCarrierIntegration[]
  selectedCarriers: Company[]
  automationSwitchsTotal: number
  automationSwitchsStatus: APILoadingStatus
  automationSwitchsError: Record<string, unknown> | null

  unrelateStatus: APILoadingStatus
  bulkUploadStatus: APILoadingStatus
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: automationSwitchState = {
  automationSwitchs: [],
  selectedCarriers: [],
  automationSwitchsTotal: 0,
  automationSwitchsStatus: APILoadingStatus.Idle,
  automationSwitchsError: null,

  unrelateStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const automationSwitchSlice = createSlice({
  name: 'automationSwitch',
  initialState,
  reducers: {
    putCarrierSelected: (
      state,
      action: PayloadAction<{
        carriers: Company[]
      }>
    ) => {
      state.selectedCarriers = action.payload.carriers
    }
  },
  extraReducers: builder => {
    asyncReducers(
      builder,
      getAutomationSwitch,
      'automationSwitchsStatus',
      (state, action) => {
        state.automationSwitchs = action.payload.results
        state.selectedCarriers = action.payload.results.map(
          automationSwitch => automationSwitch.carrier
        )
      }
    )
    asyncReducers(builder, createAutomationSwitch, 'createStatus')
  }
})

export default automationSwitchSlice.reducer
export const automationSwitchActions = {
  ...automationSwitchSlice.actions,
  getAutomationSwitch,
  createAutomationSwitch
}
