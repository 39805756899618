import { createAsyncThunk } from '@reduxjs/toolkit'
import { shipmentService } from 'services/shipment'

export const findAttachments = createAsyncThunk(
  'attachments/shipment/id',
  shipmentService.findAttachments
)

export const addAttachment = createAsyncThunk(
  'attachments/shipment/id/add',
  shipmentService.addAttachment
)

export const deleteAttachment = createAsyncThunk(
  'attachments/shipment/id/delete',
  shipmentService.deleteAttachment
)
