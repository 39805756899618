import { createSlice } from '@reduxjs/toolkit'
import { Company, User } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  getCarriers,
  createAdminCarrier,
  getCarrier,
  deleteCarrier,
  updateCarrier,
  getCompanyAdmins,
  updateCompanyAdmin,
  deleteCompanyAdmin,
  createCompanyAdmin
} from './admin-carrier.actions'

export type AdminCarrierState = {
  carriers: Company[]
  carriersTotal: number
  carriersStatus: APILoadingStatus
  carriersError: Record<string, unknown> | null
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
  deleteStatus: APILoadingStatus
  companyAdmins: User[]
  companyAdminsStatus: APILoadingStatus
  companyAdminsTotal: number
  createCompanyAdminStatus: APILoadingStatus
}

const initialState: AdminCarrierState = {
  carriers: [],
  carriersTotal: 0,
  carriersStatus: APILoadingStatus.Idle,
  carriersError: null,
  createStatus: APILoadingStatus.Idle,
  deleteStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle,
  companyAdmins: [],
  companyAdminsTotal: 0,
  companyAdminsStatus: APILoadingStatus.Idle,
  createCompanyAdminStatus: APILoadingStatus.Idle
}

const adminCarrierSlice = createSlice({
  name: 'carrier',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, getCarriers, 'carriersStatus', (state, action) => {
      state.carriers = action.payload.results
      state.carriersTotal = action.payload.total
    })
    asyncReducers(builder, createAdminCarrier, 'createStatus')
    asyncReducers(builder, createCompanyAdmin, 'createCompanyAdminStatus')
    asyncReducers(builder, deleteCarrier, 'deleteStatus')
    asyncReducers(builder, updateCarrier, 'updateStatus')
    asyncReducers(builder, getCompanyAdmins, 'companyAdminsStatus', (state, action) => {
      state.companyAdmins = action.payload.results
      state.companyAdminsTotal = action.payload.total
    })
    asyncReducers(builder, updateCompanyAdmin, 'companyAdminsStatus', (state, action) => {
      state.companyAdmins = state.companyAdmins.map(admin =>
        admin.id === action.payload.id ? action.payload : admin
      )
    })
    asyncReducers(builder, deleteCompanyAdmin, 'companyAdminsStatus')
  }
})

export default adminCarrierSlice.reducer
export const adminCarrierActions = {
  ...adminCarrierSlice.actions,
  getCarriers,
  getCarrier,
  deleteCarrier,
  createAdminCarrier,
  updateCarrier,
  updateCompanyAdmin,
  getCompanyAdmins,
  deleteCompanyAdmin,
  createCompanyAdmin
}
