import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from 'types/pagination-response'
import entityMappingService from 'services/entity-mapping'
import { EntityMappingType } from 'types/entity-mapping-type'
import { PaginationQuery } from 'types/pagination-query'
import driverService, { FindDriversQuery } from 'services/driver'
import locationService, { FindLocationsByParentQuery } from 'services/location'
import businessPartnersService, { FindBusinessPartnersQuery } from 'services/business-partners'
import { Company, Location, User } from 'models'

export const findEntityMapping = createAsyncThunk(
  'entity-mapping/source/find',
  entityMappingService.find
)

export const mapValue = createAsyncThunk('entity-mapping/map', entityMappingService.map)

export const unmapValue = createAsyncThunk('entity-mapping/unmap', entityMappingService.unmap)

export const findTargetValues = createAsyncThunk<
  PaginationResponse<User | Company | Location>,
  PaginationQuery<
    Record<string, string>,
    {
      type: EntityMappingType
      companyId?: null | string
    }
  >
>('entity-mapping/target/find', async ({ type, ...params }) => {
  let data
  switch (type) {
    case EntityMappingType.Driver: {
      data = await driverService.find({
        ...params,
        assigned: true
      } as FindDriversQuery)
      break
    }
    case EntityMappingType.Partner: {
      data = await businessPartnersService.find(params as FindBusinessPartnersQuery)
      break
    }
    case EntityMappingType.PartnerLocation: {
      data = await locationService.findByParentCompany(params as FindLocationsByParentQuery)
      break
    }
  }
  return data
})
