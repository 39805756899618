import { QRFormat } from 'models/qr-format.model'
import axios from '../base'

export class QRFormatService {
  private url = 'v1/qr/format'

  getAllQRFormats = async (): Promise<QRFormat[]> => {
    return (await axios.get(this.url)).data
  }

  getQRFormat = async (id: string): Promise<QRFormat> => {
    return (await axios.get(`${this.url}/${id}`)).data
  }
}

export default new QRFormatService()
