import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Company, Contact } from 'models'
import { FindBusinessPartnersQuery } from 'services/business-partners/business-partners.types'
import { APILoadingStatus } from 'types/api-loading-status'
import createAsyncReducers from 'utils/async-reducers'
import {
  bulkUpload,
  findBusinessPartners,
  createBusinessPartner,
  updateBusinessPartner,
  getBusinessPartnerById,
  removeBusinessPartner,
  getContacts,
  addContact,
  deleteContact
} from './business-partners.actions'

export type BusinessPartnersState = {
  businessPartners: Company[]
  businessPartnersParams: FindBusinessPartnersQuery
  businessPartnersTotal: number
  businessPartnersStatus: APILoadingStatus
  contactsByPartner: Record<string, Contact[]>
  contactsStatus: APILoadingStatus
  bulkUploadStatus: APILoadingStatus
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
  getByIdStatus: APILoadingStatus
  removeStatus: APILoadingStatus
}

const initialState: BusinessPartnersState = {
  businessPartners: [],
  businessPartnersTotal: 1,
  businessPartnersStatus: APILoadingStatus.Idle,
  businessPartnersParams: {},
  contactsByPartner: {},
  contactsStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle,
  getByIdStatus: APILoadingStatus.Idle,
  removeStatus: APILoadingStatus.Idle
}

const businessPartnersSlice = createSlice({
  name: 'business-partners',
  initialState,
  reducers: {
    setBusinessPartnersParams: (
      state,
      action: PayloadAction<FindBusinessPartnersQuery>
    ) => {
      state.businessPartnersParams = action.payload
    }
  },
  extraReducers: builder => {
    createAsyncReducers(builder, bulkUpload, 'bulkUploadStatus')
    createAsyncReducers(
      builder,
      findBusinessPartners,
      'businessPartnersStatus',
      (state, action) => {
        state.businessPartners = action.payload.results
        state.businessPartnersTotal = action.payload.total
      }
    )
    createAsyncReducers(builder, createBusinessPartner, 'createStatus')
    createAsyncReducers(builder, updateBusinessPartner, 'updateStatus')
    createAsyncReducers(builder, getBusinessPartnerById, 'getByIdStatus')
    createAsyncReducers(builder, removeBusinessPartner, 'removeStatus')
    createAsyncReducers(
      builder,
      addContact,
      'contactsStatus',
      (state, action) => {
        state.contactsByPartner[action.payload.partnerId] = [
          ...state.contactsByPartner[action.payload.partnerId],
          action.payload
        ]
      }
    )
    createAsyncReducers(
      builder,
      deleteContact,
      'updateStatus',
      (state, action) => {
        state.contactsByPartner[
          action.payload.partnerId
        ] = state.contactsByPartner[action.payload.partnerId].filter(
          contact => contact.contactId !== action.payload.contactId
        )
      }
    )
    createAsyncReducers(
      builder,
      getContacts,
      'contactsStatus',
      (state, action) => {
        state.contactsByPartner[action.payload.partnerId] = action.payload.data
      }
    )
  }
})

export default businessPartnersSlice.reducer
export const businessPartnersActions = {
  ...businessPartnersSlice.actions,
  removeBusinessPartner,
  getBusinessPartnerById,
  createBusinessPartner,
  updateBusinessPartner,
  findBusinessPartners,
  deleteContact,
  getContacts,
  addContact,
  bulkUpload
}
