import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QRFormat, QRMappingTemplate } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import createAsyncReducers from 'utils/async-reducers'
import reduce from 'utils/reduce'
import {
  getAllQRTemplates,
  getAllQRFormats,
  getQRTemplate,
  getQRFormat,
  updateQRTemplate,
  createQRTemplate,
  deleteQRTemplate
} from './pass-config.actions'

type PassConfigState = {
  qrMappingTemplates: Record<string, QRMappingTemplate>
  qrMappingTemplatesStatus: APILoadingStatus
  qrMappingTemplateUpdateStatus: APILoadingStatus
  qrMappingTemplateCreateStatus: APILoadingStatus
  qrMappingTemplateDeleteStatus: APILoadingStatus
  qrFormats: Record<string, QRFormat>
  qrFormatsStatus: APILoadingStatus
  qrMappingTemplate: QRMappingTemplate | null
}

const initialState: PassConfigState = {
  qrMappingTemplates: {},
  qrMappingTemplatesStatus: APILoadingStatus.Idle,
  qrMappingTemplateUpdateStatus: APILoadingStatus.Idle,
  qrMappingTemplateCreateStatus: APILoadingStatus.Idle,
  qrMappingTemplateDeleteStatus: APILoadingStatus.Idle,
  qrFormats: {},
  qrFormatsStatus: APILoadingStatus.Idle,
  qrMappingTemplate: null
}

const passConfigSlice = createSlice({
  name: 'pass-config',
  initialState,
  reducers: {
    setQRMappingTemplate(state, action: PayloadAction<QRMappingTemplate>) {
      state.qrMappingTemplate = action.payload
    }
  },
  extraReducers: builder => {
    createAsyncReducers(
      builder,
      getAllQRFormats,
      'qrFormatsStatus',
      (state, action) => {
        state.qrFormats = reduce(action.payload)
      }
    )
    createAsyncReducers(
      builder,
      getQRFormat,
      'qrFormatsStatus',
      (state, action) => {
        state.qrFormats[action.payload.id] = action.payload
      }
    )
    createAsyncReducers(
      builder,
      getAllQRTemplates,
      'qrMappingTemplatesStatus',
      (state, action) => {
        state.qrMappingTemplates = reduce(action.payload)
      }
    )
    createAsyncReducers(
      builder,
      getQRTemplate,
      'qrMappingTemplatesStatus',
      (state, action) => {
        state.qrMappingTemplates[action.payload.id] = action.payload
      }
    )
    createAsyncReducers(
      builder,
      updateQRTemplate,
      'qrMappingTemplateUpdateStatus'
    )
    createAsyncReducers(
      builder,
      createQRTemplate,
      'qrMappingTemplateCreateStatus'
    )
    createAsyncReducers(
      builder,
      deleteQRTemplate,
      'qrMappingTemplateDeleteStatus'
    )
  }
})

export default passConfigSlice.reducer
export const passConfigActions = {
  ...passConfigSlice.actions,
  getAllQRTemplates,
  getQRTemplate,
  getAllQRFormats,
  getQRFormat,
  updateQRTemplate,
  createQRTemplate,
  deleteQRTemplate
}
