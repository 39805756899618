import { createSlice } from '@reduxjs/toolkit'
import { ShipmentAttachment } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  addAttachment,
  deleteAttachment,
  findAttachments
} from './attachments.actions'

export type ShipmentState = {
  // loadingAttachmentsIds: string[]

  attachmentsById: Record<string, ShipmentAttachment>
  attachmentsStatus: APILoadingStatus
  attachmentsError: Record<string, unknown> | null

  addAttachmentsStatus: APILoadingStatus
}

const initialState: ShipmentState = {
  attachmentsById: {},
  attachmentsStatus: APILoadingStatus.Idle,
  addAttachmentsStatus: APILoadingStatus.Idle,
  attachmentsError: null
}

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(
      builder,
      findAttachments,
      'attachmentsStatus',
      (state, action) => {
        state.attachmentsById = action.payload.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr
          }),
          {}
        )
      }
    )
    asyncReducers(
      builder,
      addAttachment,
      'addAttachmentsStatus',
      (state, action) => {
        state.attachmentsById = {
          ...state.attachmentsById,
          [action.payload.id]: action.payload
        }
      }
    )
    asyncReducers(
      builder,
      deleteAttachment,
      'addAttachmentsStatus',
      (state, action) => {
        delete state.attachmentsById[action.payload.id]
      }
    )
  }
})

export default attachmentsSlice.reducer
export const attachmentsActions = {
  ...attachmentsSlice.actions,
  deleteAttachment,
  findAttachments,
  addAttachment
}
