import { createAsyncThunk } from '@reduxjs/toolkit'
import businessPartnersService from 'services/business-partners'

export const bulkUpload = createAsyncThunk(
  'business-partners/bulkupload',
  businessPartnersService.bulkUpload
)

export const getContacts = createAsyncThunk(
  'business-partners/:id/contacts',
  businessPartnersService.getContacts
)

export const findBusinessPartners = createAsyncThunk(
  'business-partners/get',
  businessPartnersService.find
)

export const createBusinessPartner = createAsyncThunk(
  'business-partners/create',
  businessPartnersService.createBusinessPartner
)

export const updateBusinessPartner = createAsyncThunk(
  'business-partners/:id/update',
  businessPartnersService.update
)

export const getBusinessPartnerById = createAsyncThunk(
  'business-partners/:id',
  businessPartnersService.getById
)

export const removeBusinessPartner = createAsyncThunk(
  'business-partners/:id/remove',
  businessPartnersService.removeBusinessPartner
)

export const addContact = createAsyncThunk(
  'business-partners/:id/contacts/add',
  businessPartnersService.addContact
)

export const deleteContact = createAsyncThunk(
  'business-partners/:id/contacts/delete',
  businessPartnersService.deleteContact
)
