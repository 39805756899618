import { Location } from 'models'
import { saveFile } from 'utils/save-file'
import axios from '../base'
import {
  CreateLocationRequest,
  DeleteLocationQuery,
  DeleteLocationResponse,
  FindLocationsQuery,
  FindLocationsResponse,
  UpdateLocationRequest,
  FindLocationsByParentQuery
} from './location.types'

export class LocationService {
  private url = 'v1/locations'

  find = async ({
    page = 1,
    pageSize = 15,
    query,
    shipmentId,
    include,
    exclude,
    includeBPLocations,
    ...params
  }: FindLocationsQuery): Promise<FindLocationsResponse> => {
    const { data } = await axios.get<FindLocationsResponse>(this.url, {
      params: {
        page,
        pageSize,
        q: query || undefined,
        shipment_id: shipmentId,
        includeBPLocations:
          includeBPLocations === undefined || includeBPLocations === null
            ? true
            : includeBPLocations,
        include: include && include.length > 0 ? include.join(',') : undefined,
        exclude: exclude && exclude.length > 0 ? exclude.join(',') : undefined,
        ...params
      }
    })
    return data
  }

  findByParentCompany = async ({
    page = 1,
    pageSize = 25,
    companyId,
    ...params
  }: FindLocationsByParentQuery): Promise<FindLocationsResponse> => {
    const { data } = await axios.get<FindLocationsResponse>(
      `${this.url}/partner/parent-company/${companyId}`,
      {
        params: {
          page,
          pageSize,
          ...params
        }
      }
    )
    return data
  }

  create = async (body: CreateLocationRequest): Promise<Location> => {
    const res = await axios.post<Location>(this.url, body)
    return res.data
  }

  update = async ({ partnerId, ...location }: UpdateLocationRequest): Promise<Location> => {
    const res = await axios.patch<Location>(this.url, location)
    const { companyId, ...rest } = res.data
    return {
      companyId: partnerId ? partnerId : companyId,
      externalId: location.externalId,
      ...rest
    }
  }

  updateBP = async ({ partnerId, ...location }: UpdateLocationRequest): Promise<Location> => {
    const res = await axios.patch<Location>(`${this.url}/${partnerId}`, location)
    const { companyId, ...rest } = res.data
    return {
      companyId: partnerId ? partnerId : companyId,
      ...rest
    }
  }

  bulkUpload = async (file: File) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/bulkupload?includeSummary=true&type=locations`, fd)
    return res.data
  }

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(`${this.url}/bulkupload/template?type=locations`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  deleteLocation = async (params: DeleteLocationQuery): Promise<DeleteLocationResponse> => {
    await axios.delete<void>(this.url, { params })
    return {
      locationId: params.locationId
    }
  }
}

export default new LocationService()
