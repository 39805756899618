import { saveFile } from 'utils/save-file'
import { User } from 'models'
import axios from '../base'
import { PaginationResponse } from 'types/pagination-response'
import {
  FindDriversQuery,
  FindRecentTruckResponse,
  UnrelateDriverQuery,
  UnrelateDriverResponse,
  CreateDriverQuery,
  UpdateDriverQuery
} from './driver.types'

export class DriverService {
  private url = 'v1/drivers'

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(`${this.url}/bulkupload/template`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  bulkUpload = async (file: File) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/bulkupload?includeSummary=true`, fd)
    return res.data
  }

  find = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: FindDriversQuery): Promise<PaginationResponse<User>> => {
    const res = await axios.get<PaginationResponse<User>>(this.url, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return res.data
  }

  findRecentTruck = async (driverId: string) => {
    const res = await axios.get<FindRecentTruckResponse>(
      `v1/shipments/recently-used-truck/${driverId}`
    )
    return res.data
  }

  unrelateDriver = async (params: UnrelateDriverQuery): Promise<UnrelateDriverResponse> => {
    await axios.delete(this.url, { params })
    return {
      driverId: params.driverId
    }
  }

  createOrAssociate = async ({ companyId, ...body }: CreateDriverQuery): Promise<User> => {
    const { data } = await axios.post<User>(`${this.url}?companyId=${companyId}`, body)
    return data
  }

  update = async (body: UpdateDriverQuery): Promise<User> => {
    const { data } = await axios.put<User>(this.url, body)
    return data
  }
}

export default new DriverService()
