import { createSlice } from '@reduxjs/toolkit'
import { Company } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  carriersBulkUpload,
  getCarriers,
  getCarriersIntegrations,
  createOrRelateCarrier,
  unrelateCarrier,
  updateCarrier
} from './carrier.actions'

export type CarrierState = {
  carriers: Company[]
  automationSwichCarriers: Company[]
  automationSwichCarriersTotal: number
  carriersTotal: number
  carriersStatus: APILoadingStatus
  carriersError: Record<string, unknown> | null

  unrelateStatus: APILoadingStatus
  bulkUploadStatus: APILoadingStatus
  createStatus: APILoadingStatus
  integrationStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: CarrierState = {
  carriers: [],
  automationSwichCarriers: [],
  automationSwichCarriersTotal: 0,
  carriersTotal: 0,
  carriersStatus: APILoadingStatus.Idle,
  carriersError: null,

  unrelateStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  integrationStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const carrierSlice = createSlice({
  name: 'carrier',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, updateCarrier, 'updateStatus', (state, action) => {
      state.carriers = state.carriers.map(carrier =>
        carrier.id === action.payload.id ? action.payload : carrier
      )
    })
    asyncReducers(builder, unrelateCarrier, 'unrelateStatus')
    asyncReducers(builder, createOrRelateCarrier, 'createStatus')
    asyncReducers(builder, carriersBulkUpload, 'bulkUploadStatus')
    asyncReducers(builder, getCarriers, 'carriersStatus', (state, action) => {
      state.carriers = action.payload.results
      state.carriersTotal = action.payload.total
    })
    asyncReducers(
      builder,
      getCarriersIntegrations,
      'integrationStatus',
      (state, action) => {
        state.automationSwichCarriers = action.payload.results
        state.automationSwichCarriersTotal = action.payload.total
      }
    )
  }
})

export default carrierSlice.reducer
export const carrierActions = {
  ...carrierSlice.actions,
  carriersBulkUpload,
  getCarriers,
  getCarriersIntegrations,
  createOrRelateCarrier,
  unrelateCarrier,
  updateCarrier
}
