import { Filter } from '../../models/filter.model'
import axios from '../base'
import { FilterCreateRequest } from './filters.types'

export class FiltersService {
  private url = 'v1/filters'

  find = async (): Promise<Filter[]> => {
    const res = await axios.get<Filter[]>(this.url)
    return res.data
  }

  create = async (body: FilterCreateRequest): Promise<Filter> => {
    const res = await axios.post<Filter>(this.url, {
      ...body,
      filterString: JSON.stringify(body.filterString),
    })
    return res.data
  }

  delete = async (id: string): Promise<string> => {
    await axios.delete<void>(`${this.url}/${id}`)
    return id
  }
}

export default new FiltersService()
