import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LayoutState = {
  pageTitle: string
  sidebarOpen: boolean
}

const initialState: LayoutState = {
  sidebarOpen: false,
  pageTitle: '',
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    handleSidebar(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
    },
    setPageTitle(state, action: PayloadAction<string>) {
      state.pageTitle = action.payload
    },
  },
})

export default layoutSlice.reducer
export const layoutActions = layoutSlice.actions
