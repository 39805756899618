import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  ThunkDispatch
} from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import { PERSIST_TIMEOUT, CACHE_VERSION, PERSIST_KEY } from '../constants/store'
import layout from './slices/layout'
import shipment from './slices/shipment'
import driver from './slices/driver'
import auth from './slices/auth'
import user from './slices/user'
import location from './slices/location'
import carrier from './slices/carrier'
import filters from './slices/filters'
import qrcode from './slices/qrcode'
import product from './slices/product'
import attachments from './slices/attachments'
import businessPartners from './slices/business-partners'
import businessPartnerLocations from './slices/business-partner-locations'
import importsSummary from './slices/imports-summary'
import pidxFamily from './slices/pidx-family'
import automationSwitch from './slices/automation-switch'
import productCarrierMapping from './slices/product-carrier-mapping'
import passConfig from './slices/pass-config'
import lookupTable from './slices/lookup-table'
import entityMapping from './slices/entity-mapping'
import admin, { AdminState } from './admin'
import pass, { PassState } from './pass'
import insights from './slices/insights'
import analytics from './slices/analytics'
import trailer from './slices/trailer'

export const isProduction = process.env.NODE_ENV === 'production'

const appReducer = combineReducers({
  auth,
  layout,
  shipment,
  driver,
  user,
  location,
  carrier,
  filters,
  qrcode,
  attachments,
  businessPartners,
  importsSummary,
  businessPartnerLocations,
  product,
  pidxFamily,
  automationSwitch,
  productCarrierMapping,
  lookupTable,
  passConfig,
  entityMapping,
  admin,
  pass,
  insights,
  analytics,
  trailer
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOG_OUT') {
    state = undefined
    localStorage.clear()
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export const persistConfig = {
  storage,
  key: PERSIST_KEY,
  timeout: PERSIST_TIMEOUT,
  keyPrefix: CACHE_VERSION,
  whitelist: ['auth', 'filters', 'pass']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middlewares: any[] = []
if (!isProduction && process.env.NODE_ENV !== 'test') {
  const logger = createLogger()
  middlewares.push(logger)
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !isProduction,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(middlewares)
})

export const persistor = persistStore(store)
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export type AppDispatch = ThunkDispatch<RootState, { message: string }, Action<string>>

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppState = <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean
) => useSelector(selector, equalityFn)

export const usePassState = <Selected = unknown>(
  selector: (state: PassState) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean
) => useAppState(state => selector(state.pass), equalityFn)

export const useAdminState = <Selected = unknown>(
  selector: (state: AdminState) => Selected,
  equalityFn?: (left: Selected, right: Selected) => boolean
) => useAppState(state => selector(state.admin), equalityFn)
