import { createSlice } from '@reduxjs/toolkit'
import { PIDXFamily } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import { getPIDXFamilies } from './pidx-family.actions'

export type PIDXFamilyState = {
  pidxFamilies: PIDXFamily[]
  pidxFamiliesStatus: APILoadingStatus
  pidxFamiliesError: Record<string, unknown> | null
}

const initialState: PIDXFamilyState = {
  pidxFamilies: [],
  pidxFamiliesStatus: APILoadingStatus.Idle,
  pidxFamiliesError: null
}

const pidxFamilySlice = createSlice({
  name: 'pidx-family',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(
      builder,
      getPIDXFamilies,
      'pidxFamiliesStatus',
      (state, action) => {
        state.pidxFamilies = action.payload
      }
    )
  }
})

export default pidxFamilySlice.reducer
export const pidxFamilyActions = {
  ...pidxFamilySlice.actions,
  getPIDXFamilies
}
