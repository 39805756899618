import useAppInit from 'hooks/use-app-init'
import { Route, Switch } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import { PageLoader } from 'components/PageLoader'

const PassModule = lazy(() => import('modules/Pass'))
const AuthModule = lazy(() => import('modules/Auth'))
const AppModule = lazy(() => import('modules/App'))

const Routes = () => {
  const { loading } = useAppInit()

  return loading ? (
    <PageLoader />
  ) : (
    <Switch>
      <Route path="/auth">
        <Suspense fallback={PageLoader}>
          <AuthModule />
        </Suspense>
      </Route>
      <Route path="/pass">
        <Suspense fallback={PageLoader}>
          <PassModule />
        </Suspense>
      </Route>
      <Route path="/">
        <Suspense fallback={PageLoader}>
          <AppModule />
        </Suspense>
      </Route>
    </Switch>
  )
}

export default Routes
