import { ProductCarrierMapping } from 'models'
import { DeleteResult } from 'types/delete-result'
import axios from '../base'
import {
  GetProductsCarrierMappingQuery,
  GetProductsCarrierMappingResponse,
  CreateProductCarrierMappingRequest,
  UpdateProductCarrierMappingRequest
} from './product-carrier-mapping.types'

export class ProductCarrierMappingService {
  url = 'v1/product-carrier-mapping'

  getProductCarrierMappings = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: GetProductsCarrierMappingQuery): Promise<GetProductsCarrierMappingResponse> => {
    const { data } = await axios.get(this.url, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return data
  }

  createProductCarrierMapping = async (
    mappings: CreateProductCarrierMappingRequest[]
  ): Promise<ProductCarrierMapping[]> => {
    const { data } = await axios.post(this.url, mappings)
    return data
  }

  updateProductCarrierMapping = async (
    mappings: UpdateProductCarrierMappingRequest[]
  ): Promise<ProductCarrierMapping[]> => {
    const { data } = await axios.put(this.url, mappings)
    return data
  }

  deleteProductCarrierMappings = async (
    ids: string[]
  ): Promise<DeleteResult> => {
    const { data } = await axios.delete(`${this.url}/${ids.join(',')}`)
    return data
  }

  deleteProductCarrierMapping = async (id: string): Promise<DeleteResult> => {
    const { data } = await axios.delete(`${this.url}/${id}`)
    return {
      ...data,
      id
    }
  }
}

export default new ProductCarrierMappingService()
