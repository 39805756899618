import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRole } from 'types/user-role'
import { OrderGenerationErrorType } from 'types/order-generation-error'
import { OrderGenerationStatus } from 'types/order-generation-status'

const am10 = new Date()
am10.setHours(10, 0, 0, 0)
const am11 = new Date()
am11.setHours(11, 0, 0, 0)
const pm12 = new Date()
pm12.setHours(12, 0, 0, 0)
const pm1230 = new Date()
pm1230.setHours(12, 30, 0, 0)
const pm1330 = new Date()
pm1330.setHours(13, 30, 0, 0)
const pm13 = new Date()
pm13.setHours(13, 0, 0, 0)
const pm14 = new Date()
pm14.setHours(14, 0, 0, 0)
const pm15 = new Date()
pm15.setHours(15, 0, 0, 0)
const pm17 = new Date()
pm17.setHours(17, 0, 0, 0)

const shipments = [
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e31',
    shipmentName: 'P1234, Grouse Malthouse',
    equipmentType: 'Van',
    type: 'Inbound',
    stage: 'Arrived',
    status: 'Arrived',
    supplierName: 'Grouse Malthouse',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: {
        createdAt: '2020-09-11T21:46:32.419Z',
        updatedAt: '2020-09-28T18:10:30.404Z',
        id: '2077aab8-d15e-43ad-8b65-11a465773f15',
        name: 'John',
        lastName: 'Smith',
        title: undefined,
        pictureUrl: undefined,
        email: undefined,
        phone: ' 8322891111',
        role: 'driver' as UserRole,
        status: 'active',
        createdBy: undefined,
        driver: {
          createdAt: '2020-09-07T21:22:03.153Z',
          updatedAt: '2020-09-07T21:22:03.153Z',
          id: '9e6d006a-a6e2-44a2-885c-348b9ee30d53',
          license: 'ABC111',
          state: undefined
        }
      },
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: {
      name: 'Ryder',
      createdAt: '2021-09-08T16:11:36.643Z',
      updatedAt: '2021-09-08T16:11:50.768Z',
      id: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
      address1: '335 Mill Rd, Lewiston, ID 83501, USA',
      address2: '',
      city: 'Lewiston',
      state: 'Idaho',
      zipCode: '83501',
      country: 'United States',
      domain: 'www.ryder.com',
      email: 'info@ryder.com',
      phone: '2087992000',
      logoUrl: undefined,
      website: undefined,
      isCarrier: true,
      status: 'claimed',
      scac: 'CMNO',
      ein: '820321932'
    },
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'P1234',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: am11.toUTCString(),
        deliveryDateTo: pm12.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'Grouse Malthouse',
          address1: '8121 First St, Wellington, CO 80549',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'MAB - Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e32',
    shipmentName: 'P1111, ADM',
    supplierName: 'ADM',
    equipmentType: 'Tanker',
    type: 'Inbound',
    stage: 'Unloading',
    status: 'Unloading',
    dockDoor: '2',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'P1111',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: am10.toUTCString(),
        deliveryDateTo: pm12.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'ADM Peoria',
          address1: '1 Edmund St, Peoria, IL 61602',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e33',
    shipmentName: 'P2222, Treatt',
    supplierName: 'Treatt',
    equipmentType: 'Van',
    type: 'Inbound',
    stage: 'Scheduled',
    status: 'Scheduled',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'P2222',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: pm1230.toUTCString(),
        deliveryDateTo: pm1330.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'Treatt FL',
          address1: '4900 Lakeland Commerce Pkwy, Lakeland, FL 33805',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e34',
    shipmentName: 'P2323, P33333, AZ Cold Storage',
    supplierName: 'AZ Cold Storage',
    equipmentType: 'Van',
    type: 'Inbound',
    stage: 'Scheduled',
    status: 'Scheduled',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'P2323, P33333',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: pm13.toUTCString(),
        deliveryDateTo: pm14.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'AZ Cold Storage',
          address1: '307 E Buckeye Rd, Phoenix',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e35',
    shipmentName: 'P99999, Indiana Sugars',
    supplierName: 'Indiana Sugars',
    equipmentType: 'Tanker',
    type: 'Inbound',
    stage: 'Scheduled',
    status: 'Scheduled',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'P99999',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: pm15.toUTCString(),
        deliveryDateTo: pm17.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'Indiana Sugars',
          address1: '911 Virginia St, Gary, IN 46402',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e36',
    shipmentName: 'S9898, Greenfield',
    supplierName: 'Greenfield',
    equipmentType: 'Van',
    type: 'Outbound',
    stage: 'Scheduled',
    status: 'Scheduled',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'S9898',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: pm12.toUTCString(),
        deliveryDateTo: pm13.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Greenfield',
          address1: '118 W Main St, Greenfield, IN 46140',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  },
  {
    id: 'e42d42f4-d30f-43d5-8a4e-4b15164c3e37',
    shipmentName: 'S9292, Anchor',
    supplierName: 'Anchor Brewing',
    equipmentType: 'Van',
    type: 'Outbound',
    stage: 'Scheduled',
    status: 'Scheduled',
    dockDoor: '',
    createdAt: '2021-09-20T19:44:57.808Z',
    updatedAt: '2021-09-21T15:05:04.375Z',
    shipmentNo: '0000025521',
    systemId: undefined,
    freightTerms: undefined,
    packingShipmentNo: undefined,
    pickupNo: undefined,
    proNo: undefined,
    thirdPartyFreightBilling: undefined,
    quotedCost: undefined,
    actualCost: undefined,
    freightQuoteNo: undefined,
    instructions: '',
    delayReason: undefined,
    shipperEta: undefined,
    shipperAta: undefined,
    shipperUeta: undefined,
    consigneeEta: undefined,
    consigneeAta: undefined,
    consigneeUeta: undefined,
    timeCalculatedAt: undefined,
    completedAt: undefined,
    actualPickupAt: undefined,
    actualDropoffAt: undefined,
    distanceToShipper: undefined,
    distanceToConsignee: undefined,
    totalWeight: undefined,
    freightRate: undefined,
    freightRateUom: 'total',
    carrierId: 'e7c37fd5-5cb3-4ed0-9bfe-f60e77b37e45',
    companyId: 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32',
    isUpdated: true,
    shipmentDriverRequest: {
      createdAt: '2021-09-21T15:05:08.828Z',
      updatedAt: '2021-09-21T15:05:08.938Z',
      id: '9d7e5132-2a81-4a7d-8b1d-a68bb3ed5732',
      status: 'accepted',
      cancelReason: undefined,
      truckId: undefined,
      driverId: '2077aab8-d15e-43ad-8b65-11a465773f15',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      driver: undefined,
      truck: undefined
    },
    orderGenerationDetails: {
      createdAt: '2021-10-19T17:02:05.852Z',
      updatedAt: '2021-10-19T17:02:05.852Z',
      shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
      status: 'failed' as OrderGenerationStatus,
      payload: [],
      errors: [
        {
          type: 'qr-mapping' as OrderGenerationErrorType,
          description: 'There are not QR configurations'
        }
      ]
    },
    carrier: undefined,
    collaborators: [],
    authorizations: [
      {
        createdAt: '2021-09-20T19:44:57.808Z',
        updatedAt: '2021-09-21T13:36:02.526Z',
        id: 'b9d23bc2-f4b1-43b6-a8c0-bab666949e8d',
        orderNo: '1111',
        orderSequenceNo: '1',
        poNo: 'S9292',
        accountNumber: '3333',
        supplierId: '5555',
        bolNo: '4444',
        deliveryDateFrom: pm14.toUTCString(),
        deliveryDateTo: pm15.toUTCString(),
        pickupDateFrom: '2021-09-22T18:30:00.000Z',
        pickupDateTo: '2021-09-22T20:00:00.000Z',
        shipperId: '39c05938-e601-4e1e-a206-aebfdf113255',
        consigneeId: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
        shipmentId: 'e42d42f4-d30f-43d5-8a4e-4b15165c3e38',
        shipper: {
          id: '39c05938-e601-4e1e-a206-aebfdf113255',
          name: 'Columbia',
          address1: '3160 Shop Rd, Columbia, SC 29209',
          createdAt: '2021-09-15T16:35:06.337Z',
          updatedAt: '2021-09-15T16:35:06.415Z',
          address2: undefined,
          city: 'Covington',
          state: 'WA',
          country: 'US',
          zipCode: '98042',
          latitude: '47.3585128000',
          longitude: '-122.1075890000',
          createdBy: undefined,
          companyId: 'df742978-dffd-435f-bdf3-d91d221be58c',
          externalId: undefined,
          company: {
            createdAt: '2021-09-10T19:45:06.473Z',
            updatedAt: '2021-09-14T21:06:42.204Z',
            id: 'df742978-dffd-435f-bdf3-d91d221be58c',
            name: 'Grouse Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: '8121 First St, Wellington, CO 80549',
            address2: '',
            city: 'Modesto',
            country: '',
            state: 'CA',
            zipCode: '95350',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        consignee: {
          id: 'e8ea433b-d5c4-4ad4-8a3d-fd4f424a0fb5',
          name: 'Anchor',
          address1: '495 De Haro St, San Francisco, CA 94107',
          createdAt: '2021-09-14T21:16:13.878Z',
          updatedAt: '2021-09-14T21:16:13.878Z',
          address2: '',
          city: 'Vancouver',
          state: 'Washington',
          country: 'United States',
          zipCode: '98660',
          latitude: '45.6403322000',
          longitude: '-122.6854815000',
          createdBy: undefined,
          companyId: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
          externalId: undefined,
          company: {
            createdAt: '2021-09-14T21:14:45.252Z',
            updatedAt: '2021-09-14T21:14:45.252Z',
            id: 'b6c90b2d-4b13-4a4c-86fe-8fdab6fece6d',
            name: 'Malthouse',
            email: undefined,
            phone: '',
            logoUrl: undefined,
            website: undefined,
            domain: '',
            address1: 'P.O. Box 1397',
            address2: '',
            city: '',
            country: '',
            state: '',
            zipCode: '98401',
            status: 'unclaimed',
            isCarrier: false,
            qrSmsEnabled: false,
            duns: undefined,
            ein: '',
            scac: undefined,
            defaults: {
              uom: 'lbs'
            }
          }
        },
        shipmentItems: []
      }
    ],
    trailer: {
      id: 'c4f8100b-713c-4381-9042-996324ce08cc',
      createdAt: '2021-09-20T19:46:04.652Z',
      updatedAt: undefined,
      externalId: undefined
    },
    trailer2: {
      id: 'f9f5447a-0c18-4474-9eab-cda391c5b6b1',
      createdAt: '2021-09-20T19:46:04.699Z',
      updatedAt: undefined,
      externalId: undefined
    }
  }
]

type CheckInState = {
  shipments: typeof shipments
}

type SetStatusAction = {
  id: string
  status: string
}
type SetDockDoorAction = {
  id: string
  dockDoor: string
}

const initialState: CheckInState = {
  shipments: shipments
}

const checkInSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<SetStatusAction>) => {
      state.shipments = state.shipments.map(shipment =>
        shipment.id === payload.id
          ? {
              ...shipment,
              status: payload.status
            }
          : shipment
      )
    },
    setDockDoor: (state, { payload }: PayloadAction<SetDockDoorAction>) => {
      state.shipments = state.shipments.map(shipment =>
        shipment.id === payload.id
          ? {
              ...shipment,
              dockDoor: payload.dockDoor
            }
          : shipment
      )
    }
  }
})

export const checkInActions = checkInSlice.actions
export default checkInSlice.reducer
