import { Company, User } from 'models'
import axios from '../base'
import {
  CreateAdminCompanyRequest,
  GetCompanyAdminsQuery,
  GetCompanyAdminsResponse,
  UpdateCompanyAdminRequest,
  DeleteAdminCompanyAdminResponse,
  DeleteAdminCompanyAdminRequest,
  CreateCompanyAdminRequest,
  GetAdminCompaniesQuery,
  GetAdminCompaniesResponse,
  GetAdminCompanyRequest,
  UpdateAdminCompanyRequest,
  DeleteAdminCompanyRequest,
  DeleteAdminCompanyResponse
} from './admin-company.types'

export class AdminCompanyService {
  private url = 'v1/velostics-admin/companies'

  getAdminCompanies = async ({
    page = 1,
    pageSize = 15,
    query,
    include,
    orderBy,
    ...params
  }: GetAdminCompaniesQuery): Promise<GetAdminCompaniesResponse> => {
    const res = await axios.get<GetAdminCompaniesResponse>(this.url, {
      params: {
        q: query,
        pageSize,
        page,
        include: include ? include.join(',') : undefined,
        orderBy: orderBy ? `company.${orderBy}` : undefined,
        ...params
      }
    })
    return res.data
  }

  createAdminCompany = async (company: CreateAdminCompanyRequest): Promise<Company> => {
    const { data } = await axios.post<Company>(this.url, company)
    return data
  }

  updateCompanyAdmin = async ({ user, id }: UpdateCompanyAdminRequest): Promise<User> => {
    const { data } = await axios.put<User>(`${this.url}/company-admin/${id}`, user)
    return data
  }

  getAdminCompany = async ({ companyId }: GetAdminCompanyRequest): Promise<Company> => {
    const res = await axios.get(`${this.url}/${companyId}`)
    return res.data
  }

  updateCompany = async (company: UpdateAdminCompanyRequest): Promise<Company> => {
    const { data } = await axios.put<Company>(`${this.url}/${company.id}`, company)
    return data
  }

  deleteCompany = async ({
    companyId
  }: DeleteAdminCompanyRequest): Promise<DeleteAdminCompanyResponse> => {
    await axios.delete<void>(`${this.url}/${companyId}`)
    return { companyId }
  }

  deleteCompanyAdmin = async ({
    userId
  }: DeleteAdminCompanyAdminRequest): Promise<DeleteAdminCompanyAdminResponse> => {
    await axios.delete<void>(`${this.url}/company-admin/${userId}`)
    return { userId }
  }

  getCompanyAdmins = async ({
    page = 1,
    pageSize = 15,
    query,
    ...params
  }: GetCompanyAdminsQuery): Promise<GetCompanyAdminsResponse> => {
    const res = await axios.get<GetCompanyAdminsResponse>(`${this.url}/company-admins`, {
      params: {
        q: query,
        pageSize,
        page,
        ...params
      }
    })
    return res.data
  }

  createCompanyAdmin = async ({ user, companyId }: CreateCompanyAdminRequest): Promise<User> => {
    const { data } = await axios.post<User>(`${this.url}/${companyId}/company-admin`, user)
    return data
  }
}

export default new AdminCompanyService()
