import { User } from 'models'
import axios from '../base'
import { CreateAccountRequest } from './account.types'

export class AccountService {
  private url = '/v1/account'

  async requestPasswordReset(email: string): Promise<void> {
    await axios.post<string>(`${this.url}/request-password-reset`, {
      email,
    })
  }

  resendInvitation = async (email: string): Promise<void> => {
    await axios.post<void>(`${this.url}/request-invitation`, {
      email,
    })
  }

  async getUserDetailByToken(token: string): Promise<User> {
    const { data } = await axios.get<User>(
      `${this.url}/email-token/${token}/detail`
    )
    return data
  }

  async createAccount({ token, user }: CreateAccountRequest): Promise<string> {
    const { data } = await axios.patch<string>(
      `${this.url}/email-token/${token}/verify`,
      user
    )
    return data
  }
}

export const accountService = new AccountService()
