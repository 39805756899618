import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from 'types/pagination-response'
import TrailerService, { FindTrailersQuery } from 'services/trailer'

import { Trailer } from 'models'

export const findTrailers = createAsyncThunk<PaginationResponse<Trailer>, FindTrailersQuery>(
  'trailers',
  query => {
    return TrailerService.getAll({
      ...query
    })
  }
)

export const createTrailer = createAsyncThunk('trailers/create', TrailerService.create)
export const updateTrailer = createAsyncThunk('trailers/update', TrailerService.updateTrailer)
export const deleteTrailer = createAsyncThunk('trailers/delete', TrailerService.deleteTrailer)
