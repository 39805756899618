import { createAsyncThunk } from '@reduxjs/toolkit'
import { Filter } from '../../../models/filter.model'
import filtersService from '../../../services/filters'
import { FilterCreateRequest } from '../../../services/filters/filters.types'

export const findFilters = createAsyncThunk<Filter[], void>(
  'filters/find',
  filtersService.find
)

export const deleteFilter = createAsyncThunk<string, string>(
  'filters/delete',
  filtersService.delete
)

export const createFilter = createAsyncThunk<Filter, FilterCreateRequest>(
  'filters/create',
  filtersService.create
)
