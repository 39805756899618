import { createAsyncThunk } from '@reduxjs/toolkit'
import { qrCodeService } from 'services/qrcode'

export const findQRCode = createAsyncThunk(
  'qrcode/:shipmentId',
  qrCodeService.getImage
)

export const shareQRCode = createAsyncThunk(
  'qrcode/:shipmentId/share',
  qrCodeService.share
)

export const resendQRCode = createAsyncThunk(
  'qrcode/:shipmentId/resend',
  qrCodeService.resend
)
