import { QRMappingTemplate } from 'models/qr-mapping-template.model'
import { DeleteResult } from 'types/delete-result'
import axios from '../base'
import {
  CreateQRTemplateRequest,
  UpdateQRTemplateRequest
} from './qr-template.types'

export class QRTemplateService {
  private url = 'v1/qr/template'

  getAllQRTemplates = async (): Promise<QRMappingTemplate[]> => {
    return (await axios.get(this.url)).data
  }

  getQRTemplate = async (id: string): Promise<QRMappingTemplate> => {
    return (await axios.get(`${this.url}/${id}`)).data
  }

  deleteQRTemplate = async (id: string): Promise<DeleteResult> => {
    const data = (await axios.delete(`${this.url}/${id}`)).data
    return { ...data, id }
  }

  createQRTemplate = async (
    template: CreateQRTemplateRequest
  ): Promise<QRMappingTemplate> => {
    return (await axios.post(this.url, template)).data
  }

  updateQRTemplate = async (
    template: UpdateQRTemplateRequest
  ): Promise<QRMappingTemplate> => {
    return (await axios.put(this.url, template)).data
  }
}

export default new QRTemplateService()
