import { createSlice } from '@reduxjs/toolkit'
import { Shipment } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { INSIGHTS_STATUS_OPTIONS } from '../../../constants/insightsStatus'
import { findShipmentsInsights } from './insights.actions'

export type InsightsState = {
  shipments: Shipment[]
  shipmentsStatus: APILoadingStatus
  shipmentsError: Record<string, unknown> | null
  shipmentsTotal: number
}

const initialState: InsightsState = {
  shipments: [],
  shipmentsStatus: APILoadingStatus.Idle,
  shipmentsError: null,
  shipmentsTotal: 1
}

const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, findShipmentsInsights, 'shipmentsStatus', (state, action) => {
      const insightsLocalStorage = localStorage.getItem('insights')
      const insights = insightsLocalStorage ? JSON.parse(insightsLocalStorage) : null

      state.shipmentsTotal = action.payload.total
      state.shipments = action.payload.results.map(result => ({
        ...result,
        status:
          insights && insights[result.id]
            ? insights[result.id].status
            : INSIGHTS_STATUS_OPTIONS[0].value,
        issue: insights && insights[result.id] ? insights[result.id].issue : undefined
      }))
    })
  }
})

export default insightsSlice.reducer
export const insightsActions = {
  ...insightsSlice.actions,
  findShipmentsInsights
}
