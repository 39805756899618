import { createSlice } from '@reduxjs/toolkit'
import { User } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import { findUsers } from './admin-user.actions'

export type AdminUserState = {
  users: User[]
  usersStatus: APILoadingStatus
  usersError: Record<string, unknown> | null
  usersTotal: number
}

const initialState: AdminUserState = {
  users: [],
  usersStatus: APILoadingStatus.Idle,
  usersError: null,
  usersTotal: 1
}

const adminUserSlice = createSlice({
  name: 'admin-user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, findUsers, 'usersStatus', (state, action) => {
      state.users = action.payload.results
      state.usersTotal = action.payload.total
    })
  }
})

export default adminUserSlice.reducer
export const adminUserActions = {
  ...adminUserSlice.actions,
  findUsers
}
