import { createAsyncThunk } from '@reduxjs/toolkit'
import AdminUserService, { FindUsersQuery, FindUsersResponse } from 'services/admin-user'

export const getTeam = createAsyncThunk<FindUsersResponse, FindUsersQuery>('team/find', query => {
  return AdminUserService.getTeam({
    ...query
  })
})

export const deleteTeamMember = createAsyncThunk('team/delete', AdminUserService.deleteAdmin)

export const createTeamMember = createAsyncThunk('team/create', AdminUserService.createAdmin)
