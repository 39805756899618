import { AxiosResponse } from 'axios'
import FileSaver from 'file-saver'

export const saveFile = (response: AxiosResponse) => {
  const suggestedFileName = response.headers['content-disposition'].split(
    'filename='
  )[1]
  const type = response.headers['content-type']
  const file = new Blob([response.data], { type })

  FileSaver.saveAs(file, suggestedFileName)
}
