import axios from 'services/base'
import { ImportsSummary } from 'models'
import { ImportsSummaryQuery } from './imports-summary.types'
import { saveFile } from 'utils/save-file'

class ImportSummaryService {
  url = '/v1/importsummary'

  getImportSummary = async ({
    type = 'company',
    entityId,
  }: ImportsSummaryQuery): Promise<ImportsSummary> => {
    const res = await axios.get<ImportsSummary>(
      `${this.url}?type=${type}${entityId ? `&entity=${entityId}` : ''}`
    )
    return res.data
  }

  downloadReport = async (importId: string, tableId?: string | null) => {
    const normalReport = `${this.url}/${importId}/report`
    const response = await axios.get(
      tableId
        ? `v1/lookup/table/${tableId}/importsummary/${importId}`
        : normalReport,
      {
        responseType: 'arraybuffer',
      }
    )
    saveFile(response)
  }
}

export default new ImportSummaryService()
