import { createSlice } from '@reduxjs/toolkit'
import { Company, User } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  getCompanies,
  createAdminCompany,
  updateCompanyAdmin,
  getCompanyAdmins,
  getCompany,
  createCompanyAdmin,
  deleteCompany,
  deleteCompanyAdmin,
  updateCompany
} from './admin-company.actions'

export type AdminCompanyState = {
  companies: Company[]
  companiesTotal: number
  companiesStatus: APILoadingStatus
  companiesError: Record<string, unknown> | null
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
  deleteStatus: APILoadingStatus
  companyAdmins: User[]
  companyAdminsStatus: APILoadingStatus
  companyAdminsTotal: number
  createCompanyAdminStatus: APILoadingStatus
}

const initialState: AdminCompanyState = {
  companies: [],
  companiesTotal: 0,
  companiesStatus: APILoadingStatus.Idle,
  companiesError: null,
  createStatus: APILoadingStatus.Idle,
  deleteStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle,
  companyAdmins: [],
  companyAdminsTotal: 0,
  companyAdminsStatus: APILoadingStatus.Idle,
  createCompanyAdminStatus: APILoadingStatus.Idle
}

const adminCompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, getCompanies, 'companiesStatus', (state, action) => {
      state.companiesTotal = action.payload.total
      state.companies = action.payload.results
    })
    asyncReducers(builder, getCompany, 'companiesStatus')
    asyncReducers(builder, createAdminCompany, 'createStatus')
    asyncReducers(builder, createCompanyAdmin, 'createCompanyAdminStatus')
    asyncReducers(builder, deleteCompany, 'deleteStatus')
    asyncReducers(builder, getCompanyAdmins, 'companyAdminsStatus', (state, action) => {
      state.companyAdmins = action.payload.results
      state.companyAdminsTotal = action.payload.total
    })
    asyncReducers(builder, updateCompanyAdmin, 'companyAdminsStatus', (state, action) => {
      state.companyAdmins = state.companyAdmins.map(admin =>
        admin.id === action.payload.id ? action.payload : admin
      )
    })
    asyncReducers(builder, deleteCompanyAdmin, 'companyAdminsStatus')
  }
})

export default adminCompanySlice.reducer
export const adminCompanyActions = {
  ...adminCompanySlice.actions,
  getCompanies,
  getCompany,
  createAdminCompany,
  createCompanyAdmin,
  updateCompanyAdmin,
  getCompanyAdmins,
  deleteCompany,
  deleteCompanyAdmin,
  updateCompany
}
