import { createAsyncThunk } from '@reduxjs/toolkit'
import adminCompanyService from 'services/admin-company'

export const getCompanies = createAsyncThunk(
  'admin-company/find',
  adminCompanyService.getAdminCompanies
)
export const createAdminCompany = createAsyncThunk(
  'admin-company/create',
  adminCompanyService.createAdminCompany
)
export const updateCompanyAdmin = createAsyncThunk(
  'admin-company/update/company-admin',
  adminCompanyService.updateCompanyAdmin
)
export const getCompany = createAsyncThunk(
  'admin-company/find-company',
  adminCompanyService.getAdminCompany
)
export const updateCompany = createAsyncThunk(
  'admin-company/:id/update',
  adminCompanyService.updateCompany
)
export const deleteCompany = createAsyncThunk(
  'admin-company/:id/delete',
  adminCompanyService.deleteCompany
)
export const deleteCompanyAdmin = createAsyncThunk(
  'admin-company/:id/delete-admin',
  adminCompanyService.deleteCompanyAdmin
)
export const getCompanyAdmins = createAsyncThunk(
  'admin-company/company-admins',
  adminCompanyService.getCompanyAdmins
)
export const createCompanyAdmin = createAsyncThunk(
  'admin-company/create/company-admin',
  adminCompanyService.createCompanyAdmin
)
