import { User } from 'models'
import { PaginationResponse } from 'types/pagination-response'
import axios from '../base'
import {
  CheckExistenceQuery,
  CheckExistenceResponse,
  UpdateUserRequest,
  GetAllUsersRequest,
  GetAllUsersResponse,
  FindUsersQuery,
  CreateUserRequest,
  DeleteUserRequest,
  DeleteUserResponse
} from './user.types'

export class UserService {
  private url = 'v1/users'

  checkExistence = async ({
    companyId = '',
    name,
    roles
  }: CheckExistenceQuery): Promise<CheckExistenceResponse> => {
    const res = await axios.get<CheckExistenceResponse>(
      `${this.url}/check-existence?companyId=${companyId}${name ? `&name=${name}` : ''}${
        roles ? `&role=${roles.join('&role=')}` : ''
      }`
    )
    return res.data
  }

  update = async ({ id, ...user }: UpdateUserRequest): Promise<User> => {
    const res = await axios.put<User>(`${this.url}/${id}`, {
      ...user,
      picture_url: user.pictureUrl
    })
    return res.data
  }

  find = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: FindUsersQuery): Promise<PaginationResponse<User>> => {
    const res = await axios.get<PaginationResponse<User>>(this.url, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return res.data
  }

  updateUserPicture = async (file: File): Promise<{ url: string } | null> => {
    try {
      const fd = new FormData()
      fd.append('file', file)
      const res = await axios.post<{ url: string }>('v1/upload', fd)
      return res.data
    } catch (err) {
      return null
    }
  }

  getAll = async ({ query, ...params }: GetAllUsersRequest): Promise<GetAllUsersResponse> => {
    const res = await axios.get(this.url, {
      params: {
        q: query,
        ...params
      }
    })
    return res.data
  }

  delete = async (params: DeleteUserRequest): Promise<DeleteUserResponse> => {
    await axios.delete<void>(`${this.url}/${params.userId}`)
    return {
      userId: params.userId
    }
  }

  updateUser = async (body: UpdateUserRequest): Promise<User> => {
    const { data } = await axios.put<User>(`${this.url}/${body.id}`, body)
    return data
  }

  createUser = async (body: CreateUserRequest): Promise<User> => {
    const { data } = await axios.post<User>(this.url, body, {
      params: { companyId: body.companyId }
    })
    return data
  }
}

export default new UserService()
