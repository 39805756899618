import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'services/base'
import { REHYDRATE } from 'redux-persist'
import { Auth } from 'types/auth'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  signIn,
  signInWithMicrosoft,
  forgotPassword,
  getMe,
  updateUser,
  updateCompany,
  refreshToken
} from './auth.actions'
import { User } from 'models'

export type AuthState = {
  auth: Auth | null
  authStatus: APILoadingStatus
  authError: Record<string, unknown> | null
  user: User | null
  userStatus: APILoadingStatus
  isLogged: boolean
}

const initialState: AuthState = {
  auth: null,
  authStatus: APILoadingStatus.Idle,
  authError: null,
  user: null,
  userStatus: APILoadingStatus.Idle,
  isLogged: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      state.auth = action.payload
    },
    setIsLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, (_, action) => {
      const data = action as PayloadAction<{ auth: AuthState }>
      const auth = data && data.payload && data.payload.auth && data.payload.auth.auth
      if (auth)
        axios.defaults.headers.common['Authorization'] = `${auth.token_type} ${auth.access_token}`
    })
    asyncReducers(builder, forgotPassword, 'authStatus')
    asyncReducers(builder, signIn, 'authStatus', (state, action) => {
      const auth = action.payload
      axios.defaults.headers.common['Authorization'] = `${auth.token_type} ${auth.access_token}`
      state.auth = auth
      // localStorage.setItem('auth', JSON.stringify(auth))
    })
    asyncReducers(builder, signInWithMicrosoft, 'authStatus', (state, action) => {
      const auth = action.payload
      axios.defaults.headers.common['Authorization'] = `${auth.token_type} ${auth.access_token}`
      state.auth = auth
    })
    asyncReducers(builder, refreshToken, 'authStatus', (state, action) => {
      const auth = action.payload
      axios.defaults.headers.common['Authorization'] = `${auth.token_type} ${auth.access_token}`
      state.auth = auth
      // localStorage.setItem('auth', JSON.stringify(auth))
    })
    asyncReducers(
      builder,
      getMe,
      'userStatus',
      (state, action) => {
        state.user = action.payload
      },
      state => {
        state.isLogged = false
        axios.defaults.headers.common['Authorization'] = ''
      }
    )
    asyncReducers(builder, updateUser, 'userStatus', (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      }
    })
    asyncReducers(builder, updateCompany, 'userStatus', (state, action) => {
      if (state.user) {
        state.user = {
          ...state.user,
          company: {
            ...action.payload
          }
        }
      }
    })
  }
})

export default authSlice.reducer
export const authActions = {
  ...authSlice.actions,
  forgotPassword,
  updateUser,
  signIn,
  signInWithMicrosoft,
  updateCompany,
  getMe,
  refreshToken
}
