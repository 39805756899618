import { createSlice } from '@reduxjs/toolkit'
import { User } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  findDrivers,
  findRecentTruck,
  unrelateDriver,
  bulkUpload,
  createOrAssociate,
  updateDriver
} from './driver.actions'

export type DriverState = {
  drivers: User[]
  driversStatus: APILoadingStatus
  driversError: Record<string, unknown> | null
  driversTotal: number

  recentDriverTruckStatus: APILoadingStatus
  // TODO: update this type
  recentDriverTruck: Record<string, unknown> | null

  unrelateStatus: APILoadingStatus
  bulkUploadStatus: APILoadingStatus
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: DriverState = {
  drivers: [],
  driversStatus: APILoadingStatus.Idle,
  driversError: null,
  driversTotal: 1,

  recentDriverTruckStatus: APILoadingStatus.Idle,
  recentDriverTruck: null,

  unrelateStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, unrelateDriver, 'unrelateStatus')
    asyncReducers(builder, updateDriver, 'updateStatus', (state, action) => {
      state.drivers = state.drivers.map(driver =>
        driver.id === action.payload.id ? action.payload : driver
      )
    })
    asyncReducers(builder, createOrAssociate, 'createStatus')
    asyncReducers(builder, bulkUpload, 'bulkUploadStatus')
    asyncReducers(builder, findDrivers, 'driversStatus', (state, action) => {
      state.drivers = action.payload.results
      state.driversTotal = action.payload.total
    })
    asyncReducers(
      builder,
      findRecentTruck,
      'recentDriverTruckStatus',
      (state, action) => {
        state.recentDriverTruck = action.payload
      }
    )
  }
})

export default driverSlice.reducer
export const driverActions = {
  ...driverSlice.actions,
  createOrAssociate,
  findRecentTruck,
  unrelateDriver,
  updateDriver,
  findDrivers,
  bulkUpload
}
