import { BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider } from '@velostics/theme'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from 'modules/Routes'
import { persistor, store } from './store'
import 'react-datepicker/dist/react-datepicker.css'
import GlobalStyle from './global.styled'

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <StoreProvider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <GlobalStyle />
            <Switch>
              <Routes />
            </Switch>
          </PersistGate>
        </StoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
