import { Product } from 'models'
import { DeleteResult } from 'types/delete-result'
import { saveFile } from 'utils/save-file'
import axios from '../base'
import {
  CreateProductRequest,
  GetProductsQuery,
  GetProductsResponse,
  UpdateProductRequest
} from './product.types'

export class ProductService {
  url = 'v1/product'

  getProducts = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: GetProductsQuery): Promise<GetProductsResponse> => {
    const { data } = await axios.get(this.url, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return data
  }

  createProduct = async (product: CreateProductRequest): Promise<Product> => {
    const { data } = await axios.post(this.url, product)
    return data
  }

  updateProduct = async (product: UpdateProductRequest): Promise<Product> => {
    const { data } = await axios.put(this.url, product)
    return data
  }

  deleteProduct = async (productId: string): Promise<DeleteResult> => {
    const { data } = await axios.delete(`${this.url}/${productId}`)
    return data
  }

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(`${this.url}/bulkupload/template`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  uploadProducts = async (file: File) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/bulkupload`, fd)
    return res.data
  }
}

export default new ProductService()
