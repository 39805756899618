import { Location } from 'models'
import axios from 'services/base'
import { saveFile } from 'utils/save-file'
import {
  BPLocationsBulkUploadRequest,
  GetBusinessPartnerLocationsQuery,
  GetBusinessPartnerLocationsResponse,
  CreateAndAssociateLocationRequest,
  UnrelateLocationRequest,
  UnrelateLocationResponse
} from './business-partner-locations.types'

class BusinessPartnersService {
  url = '/v1/business-partners'
  locationsUrl = 'v1/locations'

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(
      `${this.locationsUrl}/bulkupload/template?type=business-partners-locations`,
      {
        responseType: 'arraybuffer'
      }
    )
    saveFile(response)
  }

  bulkUpload = async ({ file, partnerId }: BPLocationsBulkUploadRequest) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(
      `${this.locationsUrl}/bulkupload?businessPartnerId=${partnerId}&type=business-partners-locations`,
      fd
    )
    return res.data
  }

  findLocationsByPartner = async ({
    companyId,
    partnerId,
    query,
    pageSize = 25,
    ...params
  }: GetBusinessPartnerLocationsQuery): Promise<GetBusinessPartnerLocationsResponse> => {
    const res = await axios.get<GetBusinessPartnerLocationsResponse>(
      `/v1/companies/${companyId}/partner-locations/${partnerId}`,
      {
        params: {
          q: query,
          pageSize,
          ...params
        }
      }
    )
    return { ...res.data, partnerId }
  }

  createAndAssociateLocation = async ({
    partnerId,
    ...location
  }: CreateAndAssociateLocationRequest): Promise<Location> => {
    const res = await axios.post<Location>(
      `/v1/business-partners/${partnerId}/locations`,
      location
    )
    return res.data
  }

  unrelateLocation = async ({
    partnerId,
    locationId
  }: UnrelateLocationRequest): Promise<UnrelateLocationResponse> => {
    await axios.delete<void>(`${this.url}/${partnerId}/locations/${locationId}`)
    return { partnerId, locationId }
  }
}

export default new BusinessPartnersService()
