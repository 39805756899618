// import axios from '../base'

import axios from '../base'
import { Company } from 'models'
import { PaginationResponse } from 'types/pagination-response'
import {
  GetCompaniesQuery,
  UpdateCompanyRequest,
  UnrelateLocationRequest,
  FindCompaniesQuery
} from './company.types'

export class CompanyService {
  private url = 'v1/companies'

  getById = async (id: string): Promise<Company> => {
    const res = await axios.get<Company>(`${this.url}/${id}`)
    return res.data
  }

  get = async ({ query, companyId }: GetCompaniesQuery): Promise<PaginationResponse<Company>> => {
    const res = await axios.get<PaginationResponse<Company>>(
      `${this.url}?exclude-partners=${companyId}&isCarrier=false`,
      {
        params: {
          search: query || undefined
        }
      }
    )
    return res.data
  }

  update = async (partner: UpdateCompanyRequest): Promise<Company> => {
    const res = await axios.put<Company>(this.url, partner)
    return res.data
  }

  unrelateLocation = async ({ companyId, locationId }: UnrelateLocationRequest): Promise<void> => {
    await axios.delete<void>(`${this.url}/${companyId}/locations/${locationId}`)
  }

  updatePicture = async (file: File): Promise<{ url: string } | null> => {
    try {
      const fd = new FormData()
      fd.append('file', file)
      const res = await axios.post<{ url: string }>('v1/upload', fd)
      return res.data
    } catch (err) {
      return null
    }
  }

  findCompaniesThatAreNotBP = async ({
    query,
    ...params
  }: FindCompaniesQuery): Promise<PaginationResponse<Company>> => {
    const res = await axios.get<PaginationResponse<Company>>(this.url, {
      params: {
        q: query,
        excludeBPsOfCompanyId: true,
        ...params
      }
    })
    return res.data
  }
}

export default new CompanyService()
