import axios from '../base'
import { TerminalCarrierIntegration } from 'models/terminal-carrier-integration.model'
import {
  CreateAutomationSwitchQuery,
  GetAutomationSwitchResponse,
} from './automation-switch.types'

export class AutomationSwitchService {
  private url = 'v1/qr-integration'

  getAutomationSwitch = async (
    terminalId: string
  ): Promise<GetAutomationSwitchResponse> => {
    const res = await axios.get<GetAutomationSwitchResponse>(
      `${this.url}/terminalId/${terminalId}`
    )

    return res.data
  }

  createAutomationSwitch = async ({
    automationSwitchs,
  }: CreateAutomationSwitchQuery): Promise<TerminalCarrierIntegration[]> => {
    const { data } = await axios.put<TerminalCarrierIntegration[]>(
      this.url,
      automationSwitchs
    )
    return data
  }
}

export default new AutomationSwitchService()
