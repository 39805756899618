import {
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit'
import { APILoadingStatus } from '../types/api-loading-status'

function createAsyncReducers<State, Returned, Args = void>(
  builder: ActionReducerMapBuilder<State>,
  action: AsyncThunk<Returned, Args, Record<string, unknown>>,
  statusKey?: keyof Draft<State> | ((state: Draft<State>, status: APILoadingStatus) => void),
  fulfilled?: (state: Draft<State>, action: PayloadAction<Returned>) => void,
  rejected?: (
    state: Draft<State>,
    action: PayloadAction<
      unknown,
      string,
      {
        arg: Args
        requestId: string
        rejectedWithValue: boolean
        requestStatus: 'rejected'
        aborted: boolean
        condition: boolean
      },
      SerializedError
    >
  ) => void
) {
  builder.addCase(action.pending, state => {
    if (statusKey) {
      if (typeof statusKey === 'function') {
        statusKey(state, APILoadingStatus.Loading)
      } else {
        state[statusKey] = APILoadingStatus.Loading as Draft<State>[keyof Draft<State>]
      }
    }
  })
  builder.addCase(action.fulfilled, (state, action) => {
    if (statusKey) {
      if (typeof statusKey === 'function') {
        statusKey(state, APILoadingStatus.Succeeded)
      } else {
        state[statusKey] = APILoadingStatus.Succeeded as Draft<State>[keyof Draft<State>]
      }
    }
    if (fulfilled) fulfilled(state, action)
  })
  builder.addCase(action.rejected, (state, action) => {
    if (statusKey) {
      if (typeof statusKey === 'function') {
        statusKey(state, APILoadingStatus.Failed)
      } else {
        state[statusKey] = APILoadingStatus.Failed as Draft<State>[keyof Draft<State>]
      }
    }
    if (rejected) rejected(state, action)
  })
}

export default createAsyncReducers
