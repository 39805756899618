import { ShipmentDriverRequest, Shipment, ShipmentAttachment } from 'models'
import axios from '../base'
import { PaginationResponse } from 'types/pagination-response'
import { ShipmentFilters } from '../filters/filters.types'
import {
  RegenerateResponse,
  AddAttachmentRequest,
  AssignDriverRequest,
  CloneShipmentRequest,
  FindShipmentsQuery,
  UpdateShipmentRequest,
  DeleteAttachmentRequest
} from './shipment.types'

const prepareQuery = ({
  createdFrom,
  createdTo,
  etdFrom,
  etdTo,
  dueDateFrom,
  dueDateTo,
  owner_id,
  origin,
  destination,
  stage
}: ShipmentFilters) => {
  const createdFromQuery =
    createdFrom && createdFrom.value
      ? `${createdFrom.value ? `&createdFrom=${createdFrom.value}` : ''}`
      : ''
  const createdToQuery =
    createdTo && createdTo.value ? `${createdTo.value ? `&createdTo=${createdTo.value}` : ''}` : ''
  const etdFromQuery =
    etdFrom && etdFrom.value ? `${etdFrom.value ? `&etdFrom=${etdFrom.value}` : ''}` : ''
  const etdToQuery = etdTo && etdTo.value ? `${etdTo.value ? `&etdTo=${etdTo.value}` : ''}` : ''
  const dueDateFromQuery =
    dueDateFrom && dueDateFrom.value
      ? `${dueDateFrom.value ? `&dueDateFrom=${dueDateFrom.value}` : ''}`
      : ''
  const dueDateToQuery =
    dueDateTo && dueDateTo.value ? `${dueDateTo.value ? `&dueDateTo=${dueDateTo.value}` : ''}` : ''
  const ownerQuery = `${owner_id && owner_id.value ? `&ownerId=${owner_id.value}` : ''}`
  const originQuery = `${origin && origin.value ? `&origin=${origin.value}` : ''}`
  const destinationQuery = `${
    destination && destination.value ? `&destination=${destination.value}` : ''
  }`
  const stagesQuery = stage ? `${stage.length > 0 ? `&stage=${stage.join('&stage=')}` : ''}` : ''
  return `${createdFromQuery}${createdToQuery}${etdFromQuery}${etdToQuery}${dueDateFromQuery}${dueDateToQuery}${ownerQuery}${originQuery}${destinationQuery}${stagesQuery}`
}

export class ShipmentService {
  private url = 'v1/shipments'

  find = async ({
    page = 1,
    pageSize = 20,
    query,
    order,
    orderBy,
    ...filters
  }: FindShipmentsQuery): Promise<PaginationResponse<Shipment>> => {
    const url = `${this.url}?page=${page}&pageSize=${pageSize}${order ? `&order=${order}` : ''}${
      orderBy ? `&orderBy=${orderBy}` : ''
    }${prepareQuery(filters)}`
    const params: Record<string, string> = {}
    if (query) params['q'] = query
    const res = await axios.get<PaginationResponse<Shipment>>(url, { params })
    return res.data
  }

  findShipmentsInsights = async ({
    page = 1,
    pageSize = 20,
    query,
    order,
    orderBy
  }: FindShipmentsQuery): Promise<PaginationResponse<Shipment>> => {
    const url = `${this.url}/insights?page=${page}&pageSize=${pageSize}${
      order ? `&order=${order}` : ''
    }${orderBy ? `&orderBy=${orderBy}` : ''}`
    const params: Record<string, string> = {}
    if (query) params['q'] = query
    const res = await axios.get<PaginationResponse<Shipment>>(url, { params })
    return res.data
  }

  findById = async (id: string): Promise<Shipment> => {
    const res = await axios.get<Shipment>(`${this.url}/${id}`)
    return res.data
  }

  assignDriver = async ({
    driverId,
    shipmentId,
    ...body
  }: AssignDriverRequest): Promise<ShipmentDriverRequest> => {
    const companyId = 'cb9dee02-c7b1-4d7c-9b2c-253176e04a32'
    const res = await axios.post<ShipmentDriverRequest>(
      `${this.url}/truck-driver?companyId=${companyId}`,
      {
        driverId,
        shipmentId,
        trailerId: body.trailerId,
        trailerId2: body.trailerId2,
        ...(body.truck && {
          truck: {
            dotNo: body.truck
          }
        })
      }
    )
    return res.data
  }

  update = async ({ id, ...body }: UpdateShipmentRequest): Promise<Shipment> => {
    const res = await axios.patch<Shipment>(`${this.url}/${id}`, body)
    return res.data
  }

  create = async (): Promise<Shipment> => {
    const res = await axios.post<Shipment>(`${this.url}`, {
      type: 'material'
    })
    return res.data
  }

  clone = async ({ quantity = 1, ...body }: CloneShipmentRequest): Promise<Shipment[]> => {
    const res = await axios.post<Shipment[]>(`${this.url}/clone`, {
      quantity,
      ...body
    })
    return res.data
  }

  findAttachments = async (id: string): Promise<ShipmentAttachment[]> => {
    const res = await axios.get<ShipmentAttachment[]>(`${this.url}/${id}/attachments`)
    return res.data
  }

  addAttachment = async ({ id, ...body }: AddAttachmentRequest): Promise<ShipmentAttachment> => {
    const fd = new FormData()
    fd.append('file', body.file)
    fd.append('description', body.description || '')
    fd.append('title', body.title)
    fd.append('type', body.type)
    const res = await axios.post<ShipmentAttachment>(`${this.url}/${id}/attachments`, fd)
    return res.data
  }

  deleteAttachment = async ({
    id,
    attachmentId
  }: DeleteAttachmentRequest): Promise<ShipmentAttachment> => {
    const { data } = await axios.delete(`${this.url}/${id}/attachments/${attachmentId}`)
    // API doesn't return the ID
    return { ...data, id: attachmentId }
  }

  regenerateOrderByIds = async (ids: string[]): Promise<RegenerateResponse> => {
    const { data } = await axios.put(`${this.url}/regenerate-order`, ids)
    return data
  }
}

export const shipmentService = new ShipmentService()
