import { createAsyncThunk } from '@reduxjs/toolkit'
import businessPartnerLocationsService from 'services/business-partner-locations'
import locationService from 'services/location'

export const bulkUpload = createAsyncThunk(
  'business-partner-locations/bulkupload',
  businessPartnerLocationsService.bulkUpload
)

export const findLocationsByPartner = createAsyncThunk(
  'business-partner-locations/:partnerId/find',
  businessPartnerLocationsService.findLocationsByPartner
)

export const createAndAssociateLocation = createAsyncThunk(
  'business-partner-locations/create',
  businessPartnerLocationsService.createAndAssociateLocation
)

export const updateLocation = createAsyncThunk(
  'business-partner-locations/update',
  locationService.updateBP
)

export const unrelateLocation = createAsyncThunk(
  'business-partner-locations/unrelate',
  businessPartnerLocationsService.unrelateLocation
)
