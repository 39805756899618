import { User } from 'models'
import { Auth } from 'types/auth'
import axios from '../base'
import { SignInRequestBody } from './auth.types'

export class AuthService {
  private url = '/oauth'

  signIn = async (values: SignInRequestBody): Promise<Auth> => {
    const formData = new URLSearchParams()
    formData.append('grant_type', 'password')
    formData.append('username', values.username)
    formData.append('password', values.password)

    const { data } = await axios.post<Auth>(`${this.url}/login`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(process.env.REACT_APP_BASIC_TOKEN || '')}`
      }
    })
    return data
  }

  me = async (token?: string): Promise<User> => {
    let data: User | null = null
    if (token) {
      axios.defaults.headers.common['Authorization'] = token
      const res = await axios.get<User>(`${this.url}/me`, {
        headers: { Authorization: token }
      })
      data = res.data
    } else {
      const res = await axios.get<User>(`${this.url}/me`)
      data = res.data
    }
    if (!data) {
      throw new Error('Not found')
    }
    return data
  }

  refreshToken = async (token: string): Promise<Auth> => {
    const formData = new URLSearchParams()
    formData.append('grant_type', 'refresh_token')
    formData.append('refresh_token', token)
    const { data } = await axios.post<Auth>(`${this.url}/refresh-token`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(process.env.REACT_APP_BASIC_TOKEN || '')}`
      }
    })
    return data
  }

  signInWithMicrosoft = async (oauthCode: string): Promise<Auth> => {
    const { data } = await axios.post<Auth>(
      `${this.url}/microsoft`,
      {},
      {
        headers: {
          Authorization: `Basic ${btoa(process.env.REACT_APP_BASIC_TOKEN || '')}`
        },
        params: {
          oauthCode
        }
      }
    )
    return data
  }
}

export const authService = new AuthService()
