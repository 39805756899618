import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LookupTableValue } from 'models'
import { LookupTable } from 'models/lookup-table.model'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  deleteLookupValue,
  getLookupTables,
  getLookupTable,
  editLookupTable,
  createLookupTable,
  bulkUploadLookupValues,
  updateLookupTableValues
} from './lookup-table.actions'

type LookupTableState = {
  selectedLookupTable: LookupTable | null
  filteredLookupValues: LookupTableValue[]
  lookupTables: LookupTable[]
  lookupTablesTotal: number
  lookupTablesStatus: APILoadingStatus
  lookupValuesStatus: APILoadingStatus
  lookupTablesError: Record<string, unknown> | null
  deleteStatus: APILoadingStatus
  bulkUploadStatus: APILoadingStatus
  lookupTableModalStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: LookupTableState = {
  selectedLookupTable: null,
  filteredLookupValues: [],
  lookupTables: [],
  lookupTablesTotal: 0,
  lookupTablesStatus: APILoadingStatus.Idle,
  lookupValuesStatus: APILoadingStatus.Idle,
  lookupTablesError: null,
  deleteStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle,
  lookupTableModalStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const lookupTableSlice = createSlice({
  name: 'lookup-table',
  initialState,
  reducers: {
    searchLookupValues: (state, action: PayloadAction<Record<string, string>>) => {
      const keys = Object.keys(action.payload)
      const regExps: Record<string, RegExp> = keys.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: action.payload[curr] ? new RegExp(action.payload[curr], 'i') : null
        }),
        {}
      )

      state.filteredLookupValues =
        state.selectedLookupTable?.lookupValues.filter(lookup => {
          let valid = true
          if (regExps['atObject']) {
            if (lookup.atObjectLocation) {
              valid = regExps['atObject'].test(lookup.atObjectLocation.name)
            }
            if (lookup.atObjectCarrier) {
              valid = regExps['atObject'].test(lookup.atObjectCarrier.name)
            }
          }
          if (!valid) return false
          if (regExps['forObject']) {
            if (lookup.forObjectLocation) {
              valid = regExps['forObject'].test(lookup.forObjectLocation.name)
            }
            if (lookup.forObjectCarrier) {
              valid = regExps['forObject'].test(lookup.forObjectCarrier.name)
            }
            if (lookup.forObjectDriver) {
              valid = regExps['forObject'].test(lookup.forObjectDriver.name)
            }
            if (lookup.forObjectUserDefined) {
              valid = regExps['forObject'].test(lookup.forObjectUserDefined)
            }
          }
          if (!valid) return false
          if (regExps['returnValue']) {
            valid = regExps['returnValue'].test(lookup.returnValue || '')
          }
          return valid
        }) || []
    }
  },
  extraReducers: builder => {
    asyncReducers(builder, getLookupTables, 'lookupTablesStatus', (state, action) => {
      state.lookupTables = action.payload
    })
    asyncReducers(builder, getLookupTable, 'lookupValuesStatus', (state, action) => {
      state.selectedLookupTable = action.payload
      state.filteredLookupValues = action.payload.lookupValues
    })
    asyncReducers(builder, deleteLookupValue, 'deleteStatus', (state, action) => {
      if (state.selectedLookupTable) {
        const { lookupValues, ...rest } = state.selectedLookupTable
        state.selectedLookupTable = {
          ...rest,
          lookupValues: lookupValues.filter(value => value.id !== action.payload)
        }
        state.filteredLookupValues = [...lookupValues.filter(value => value.id !== action.payload)]
      }
    })
    asyncReducers(builder, editLookupTable, 'lookupTableModalStatus', (state, action) => {
      state.lookupTables = state.lookupTables.map(table =>
        table.id === action.payload.id ? action.payload : table
      )
    })
    asyncReducers(builder, createLookupTable, 'lookupTableModalStatus')
    asyncReducers(builder, bulkUploadLookupValues, 'bulkUploadStatus')
    asyncReducers(builder, updateLookupTableValues, 'updateStatus', (state, action) => {
      if (state.selectedLookupTable) {
        const existingIds = state.selectedLookupTable.lookupValues.map(value => value.id)
        state.selectedLookupTable.lookupValues = [
          ...state.selectedLookupTable.lookupValues,
          // Don't update existing lookup values
          ...action.payload.filter(data => !existingIds.includes(data.id))
        ]
        state.filteredLookupValues = [
          ...state.filteredLookupValues,
          // Don't update existing lookup values
          ...action.payload.filter(data => !existingIds.includes(data.id))
        ]
      }
    })
  }
})

export default lookupTableSlice.reducer
export const lookupTableActions = {
  ...lookupTableSlice.actions,
  getLookupTables,
  getLookupTable,
  deleteLookupValue,
  editLookupTable,
  createLookupTable,
  bulkUploadLookupValues,
  updateLookupTableValues
}
