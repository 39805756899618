import { DeleteResult } from 'types/delete-result'
import { ReportLink } from '../../models'
import axios from '../base'
import {
  CreateReportLinkRequest,
  GetAllAnalyticsResponse,
  UpdateReportLinkRequest
} from './analytics.types'

export class AnalyticsService {
  url = 'v1/analytics'

  getAllAnalytics = async (): Promise<GetAllAnalyticsResponse> => {
    const { data } = await axios.get(this.url)
    return data
  }

  createReportLink = async (report: CreateReportLinkRequest): Promise<ReportLink> => {
    const { data } = await axios.post(this.url, report)
    return data
  }

  updateReportLink = async ({ id, ...analytic }: UpdateReportLinkRequest): Promise<ReportLink> => {
    const { data } = await axios.put(`${this.url}/${id}`, analytic)
    return data
  }

  getReportLinkById = async (analyticId: string): Promise<ReportLink> => {
    const { data } = await axios.get(`${this.url}/${analyticId}`)
    return data
  }

  deleteReportLink = async (analyticId: string): Promise<DeleteResult> => {
    const { data } = await axios.delete(`${this.url}/${analyticId}`)
    return data
  }
}

export default new AnalyticsService()
