import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { findQRCode, shareQRCode, resendQRCode } from './qrcode.actions'

export type QRCodeState = {
  qrcode: string
  qrcodeStatus: APILoadingStatus
  shareQRCodeStatus: APILoadingStatus
}

const initialState: QRCodeState = {
  qrcode: '',
  qrcodeStatus: APILoadingStatus.Idle,
  shareQRCodeStatus: APILoadingStatus.Idle
}

const qrcodeSlice = createSlice({
  name: 'qrcode',
  initialState,
  reducers: {
    setQrCode: (state, action: PayloadAction<string>) => {
      state.qrcode = action.payload
    }
  },
  extraReducers: builder => {
    asyncReducers(builder, shareQRCode, 'shareQRCodeStatus')
    asyncReducers(builder, findQRCode, 'qrcodeStatus', (state, action) => {
      state.qrcode = action.payload
    })
    asyncReducers(builder, resendQRCode, 'shareQRCodeStatus')
  }
})

export default qrcodeSlice.reducer
export const qrcodeActions = {
  ...qrcodeSlice.actions,
  shareQRCode,
  findQRCode,
  resendQRCode
}
