import { EntityMappingValue } from 'models'
import { DeleteResult } from 'types/delete-result'
import { EntityMappingType } from 'types/entity-mapping-type'
import { MapEntityMappingRequest } from '.'
import axios from '../base'
import {
  FindEntityMappingRequest,
  FindEntityMappingResponse
} from './entity-mapping.types'

export class EntityMappingService {
  private url = 'v1/entity-mapping'

  find = async ({
    type,
    targetId,
    ...params
  }: FindEntityMappingRequest): Promise<FindEntityMappingResponse> => {
    const { data } = await axios.get(`${this.url}/table/${type}/${targetId}`, {
      params
    })
    return {
      targetId,
      data,
      type
    }
  }

  map = async ({
    targetCompanyId,
    type,
    ...body
  }: MapEntityMappingRequest): Promise<
    EntityMappingValue & { type: EntityMappingType; targetCompanyId: string }
  > => {
    const { data } = await axios.post(
      `${this.url}/value/${type}/${targetCompanyId}`,
      body
    )
    return { ...data, type, targetCompanyId }
  }

  unmap = async (id: string): Promise<DeleteResult> => {
    const { data } = await axios.delete<DeleteResult>(`${this.url}/value/${id}`)
    return {
      id,
      affected: data.affected,
      raw: data.raw
    }
  }
}

export default new EntityMappingService()
