import { createAsyncThunk } from '@reduxjs/toolkit'
import productService from 'services/product'

export const getProducts = createAsyncThunk(
  'product/find',
  productService.getProducts
)

export const createProduct = createAsyncThunk(
  'product/create',
  productService.createProduct
)

export const updateProduct = createAsyncThunk(
  'product/update',
  productService.updateProduct
)

export const deleteProduct = createAsyncThunk(
  'product/delete/:id',
  productService.deleteProduct
)

export const uploadProducts = createAsyncThunk(
  'product/upload',
  productService.uploadProducts
)
