import axios from '../base'
import { ShareQRCodeRequest } from './qrcode.types'
export class QRCodeService {
  url = 'v1/shipments'

  getImage = async (shipmentId: string) => {
    const { data } = await axios.get<string>(
      `${this.url}/${shipmentId}/generate-qr`
    )
    return data
  }

  share = async ({ shipmentId, contacts }: ShareQRCodeRequest) => {
    const { data } = await axios.put(
      `${this.url}/${shipmentId}/share-qr`,
      contacts
    )
    return data
  }

  resend = async (ids: string[]) => {
    const { data } = await axios.put(`${this.url}/resend-order`, ids)
    return data
  }
}

export const qrCodeService = new QRCodeService()
