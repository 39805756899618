import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../..'
import { User } from 'models'
import { PaginationResponse } from 'types/pagination-response'
import { driverService } from 'services/driver'
import { FindDriversQuery, UnrelateDriverQuery, UnrelateDriverResponse } from 'services/driver'

export const findDrivers = createAsyncThunk<PaginationResponse<User>, FindDriversQuery>(
  'driver/find',
  (query, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    return driverService.find({
      ...query,
      companyId: query.companyId || state.auth.user?.company?.id
    })
  }
)
export const findRecentTruck = createAsyncThunk(
  'driver/find/recent-truck',
  driverService.findRecentTruck
)
export const unrelateDriver = createAsyncThunk<UnrelateDriverResponse, UnrelateDriverQuery>(
  'driver/:id/unrelate',
  (query, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    return driverService.unrelateDriver({
      companyId: state.auth.user && state.auth.user.company ? state.auth.user.company.id : '',
      ...query
    })
  }
)
export const bulkUpload = createAsyncThunk('driver/bulkupload', driverService.bulkUpload)
export const createOrAssociate = createAsyncThunk('driver/create', driverService.createOrAssociate)
export const updateDriver = createAsyncThunk('driver/:id/update', driverService.update)
