import { createSlice } from '@reduxjs/toolkit'
import { ProductCarrierMapping } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  getProductCarrierMappings,
  createProductCarrierMapping,
  updateProductCarrierMapping,
  deleteProductCarrierMapping,
  deleteProductCarrierMappings
} from './product-carrier-mapping.actions'

export type ProductState = {
  productCarrierMappings: ProductCarrierMapping[]
  productCarrierMappingsStatus: APILoadingStatus
  productCarrierMappingsError: Record<string, unknown> | null
  productCarrierMappingsTotal: number
  updateStatus: APILoadingStatus
  createStatus: APILoadingStatus
  deleteStatus: APILoadingStatus
}

const initialState: ProductState = {
  productCarrierMappings: [],
  productCarrierMappingsStatus: APILoadingStatus.Idle,
  productCarrierMappingsError: null,
  productCarrierMappingsTotal: 0,
  updateStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  deleteStatus: APILoadingStatus.Idle
}

const productCarrierMappingSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, deleteProductCarrierMappings, 'deleteStatus')
    asyncReducers(
      builder,
      deleteProductCarrierMapping,
      'deleteStatus',
      (state, action) => {
        state.productCarrierMappingsTotal =
          state.productCarrierMappingsTotal - 1
        state.productCarrierMappings = state.productCarrierMappings.filter(
          mapping => mapping.id !== action.payload.id
        )
      }
    )
    asyncReducers(builder, createProductCarrierMapping, 'createStatus')
    asyncReducers(builder, updateProductCarrierMapping, 'updateStatus')
    asyncReducers(
      builder,
      getProductCarrierMappings,
      'productCarrierMappingsStatus',
      (state, action) => {
        state.productCarrierMappings = action.payload.results
        state.productCarrierMappingsTotal = action.payload.total
      }
    )
  }
})

export default productCarrierMappingSlice.reducer
export const productCarrierMappingActions = {
  ...productCarrierMappingSlice.actions,
  getProductCarrierMappings,
  createProductCarrierMapping,
  updateProductCarrierMapping,
  deleteProductCarrierMapping,
  deleteProductCarrierMappings
}
