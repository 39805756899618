import { createAsyncThunk } from '@reduxjs/toolkit'
import analyticsService from 'services/analytics'

export const getAllAnalytics = createAsyncThunk(
  'analytics/findAll',
  analyticsService.getAllAnalytics
)

export const createReportLink = createAsyncThunk(
  'analytics/create',
  analyticsService.createReportLink
)

export const updateReportLink = createAsyncThunk(
  'analytics/update',
  analyticsService.updateReportLink
)

export const deleteReportLink = createAsyncThunk(
  'analytics/delete/:id',
  analyticsService.deleteReportLink
)

export const getReportLinkById = createAsyncThunk(
  'analytics/find/:id',
  analyticsService.getReportLinkById
)
