import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Filter } from 'models/filter.model'
import { FindShipmentsQuery } from 'services/shipment'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { createFilter, deleteFilter, findFilters } from './filters.actions'

export type FiltersState = {
  filters: Filter[]
  filtersStatus: APILoadingStatus
  filtersError: Record<string, unknown> | null

  currentFilter: FindShipmentsQuery
  selectedColumns: string[]
}

const initialState: FiltersState = {
  filters: [],
  filtersStatus: APILoadingStatus.Idle,
  filtersError: null,

  currentFilter: {},
  selectedColumns: [
    'shipmentName',
    'shipmentNo',
    'type',
    'authorizations.0.shipper.name',
    'authorizations.0.consignee.name',
    'status',
    'authorizations.0.pickupDateFrom',
    'authorizations.0.deliveryDateFrom',
    'carrier.name',
    'authorizations.0.shipper.name',
    'authorizations.0.consignee.name',
    'driverUserData.name',
    'authorizations.0.poNo',
    'authorizations.0.orderNo'
  ]
}

const filtersSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setCurrentFilter: (state, action: PayloadAction<FindShipmentsQuery>) => {
      state.currentFilter = action.payload
    },
    setSelectedColumns: (state, action: PayloadAction<string[]>) => {
      state.selectedColumns = action.payload
    }
  },
  extraReducers: builder => {
    asyncReducers(builder, findFilters, 'filtersStatus', (state, action) => {
      state.filters = action.payload
    })
    asyncReducers(builder, deleteFilter, 'filtersStatus', (state, action) => {
      state.filters = state.filters.filter(f => f.id !== action.payload)
    })
    asyncReducers(builder, createFilter, 'filtersStatus', (state, action) => {
      state.filters = [...state.filters, action.payload]
    })
  }
})

export default filtersSlice.reducer
export const filtersActions = {
  ...filtersSlice.actions,
  findFilters,
  deleteFilter,
  createFilter
}
