import { createSlice } from '@reduxjs/toolkit'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { findTrailers, createTrailer, updateTrailer } from './trailer.actions'

import { Trailer } from 'models'

export type TrailerState = {
  trailers: Trailer[]
  trailersStatus: APILoadingStatus
  trailersError: Record<string, unknown> | null
  trailersTotal: number
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: TrailerState = {
  trailers: [],
  trailersStatus: APILoadingStatus.Idle,
  trailersError: null,
  trailersTotal: 1,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const trailerSlice = createSlice({
  name: 'trailer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, createTrailer, 'createStatus')
    asyncReducers(builder, findTrailers, 'trailersStatus', (state, action) => {
      state.trailers = action.payload.results
      state.trailersTotal = action.payload.total
    })
    asyncReducers(builder, updateTrailer, 'updateStatus')
  }
})

export default trailerSlice.reducer
export const trailerActions = {
  ...trailerSlice.actions,
  findTrailers,
  createTrailer,
  updateTrailer
}
