import { createSlice } from '@reduxjs/toolkit'
import { ImportsSummary } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import createAsyncReducers from 'utils/async-reducers'
import { getImportSummary } from './imports-summary.actions'

export type ImportsSummaryState = {
  loading: APILoadingStatus
  summary: ImportsSummary | null
}

const initialState: ImportsSummaryState = {
  loading: APILoadingStatus.Idle,
  summary: null
}

const importsSummarySlice = createSlice({
  name: 'imports-summary',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createAsyncReducers(
      builder,
      getImportSummary,
      'loading',
      (state, action) => {
        state.summary = action.payload
      }
    )
  }
})

export default importsSummarySlice.reducer
export const importsSummaryActions = {
  ...importsSummarySlice.actions,
  getImportSummary
}
