import { createAsyncThunk } from '@reduxjs/toolkit'
import automationSwitchService, {
  GetAutomationSwitchResponse,
} from '../../../services/automation-switch'

export const getAutomationSwitch = createAsyncThunk<
  GetAutomationSwitchResponse,
  string
>('automationSwitch/find', automationSwitchService.getAutomationSwitch)

export const createAutomationSwitch = createAsyncThunk(
  'automationSwitch/create',
  automationSwitchService.createAutomationSwitch
)
