import { createAsyncThunk } from '@reduxjs/toolkit'
import locationService from '../../../services/location'

export const findLocations = createAsyncThunk(
  'location/find',
  locationService.find
)

export const updateLocation = createAsyncThunk(
  'location/update',
  locationService.update
)

export const bulkUpload = createAsyncThunk(
  'location/bulkupload',
  locationService.bulkUpload
)

export const deleteLocation = createAsyncThunk(
  'location/delete',
  locationService.deleteLocation
)
export const createLocation = createAsyncThunk(
  'location/create',
  locationService.create
)
