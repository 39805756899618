import { createSlice } from '@reduxjs/toolkit'
import { Company, Location, User } from 'models'
import { EntityMappingTable } from 'models/entity-mapping-table.model'
import { PaginationResponse } from 'types/pagination-response'
import { APILoadingStatus } from 'types/api-loading-status'
import createAsyncReducers from 'utils/async-reducers'
import { findEntityMapping, findTargetValues, mapValue, unmapValue } from './entity-mapping.actions'

type State = {
  mappingStatus: APILoadingStatus
  mappings: Record<string, PaginationResponse<EntityMappingTable>>
  targetValuesStatus: APILoadingStatus
  targetValues: {
    total: number
    data: (User | Company | Location)[]
  }
}

const initialState: State = {
  mappingStatus: APILoadingStatus.Idle,
  mappings: {},
  targetValuesStatus: APILoadingStatus.Idle,
  targetValues: {
    total: 1,
    data: []
  }
}

const entityMappingSlice = createSlice({
  name: 'entity-mapping',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createAsyncReducers(builder, findEntityMapping, 'mappingStatus', (state, { payload }) => {
      state.mappings[`${payload.targetId},${payload.type}`] = payload.data
    })
    createAsyncReducers(builder, findTargetValues, 'targetValuesStatus', (state, { payload }) => {
      state.targetValues = {
        total: payload.total,
        data: payload.results
      }
    })
    createAsyncReducers(builder, mapValue, 'mappingStatus', (state, { payload }) => {
      const entity = state.targetValues.data.find(value => value.id === payload.targetEntityId)
      if (entity) {
        const key = `${payload.targetCompanyId},${payload.type}`
        state.mappings[key].results = state.mappings[key].results.map(data =>
          data.sourceEntity.id === payload.sourceEntityId
            ? {
                ...data,
                targetEntity: entity,
                entityValueId: payload.id
              }
            : data
        )
      }
    })
    createAsyncReducers(builder, unmapValue, 'mappingStatus')
  }
})

export default entityMappingSlice.reducer
export const entityMappingActions = {
  ...entityMappingSlice.actions,
  findEntityMapping,
  findTargetValues,
  mapValue,
  unmapValue
}
