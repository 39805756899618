import { Company, Contact } from 'models'
import axios from 'services/base'
import { PaginationResponse } from 'types/pagination-response'
import { saveFile } from 'utils/save-file'
import {
  FindBusinessPartnersQuery,
  CreateBusinessPartnersRequest,
  GetBusinessPartnerContactsResponse,
  AddContactRequest,
  DeleteContactRequest,
  UpdateBusinessPartnerRequest
} from './business-partners.types'

class BusinessPartnersService {
  url = '/v1/business-partners'

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(`${this.url}/bulkupload/template`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  bulkUpload = async (file: File) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/bulkupload`, fd)
    return res.data
  }

  find = async ({
    page = 1,
    pageSize = 25,
    orderBy,
    query,
    ...params
  }: FindBusinessPartnersQuery): Promise<PaginationResponse<Company>> => {
    const res = await axios.get<PaginationResponse<Company>>(this.url, {
      params: {
        page,
        pageSize,
        orderBy: orderBy ? `partner.${orderBy}` : null,
        q: query,
        ...params
      }
    })
    return res.data
  }

  createBusinessPartner = async (
    businessPartner: CreateBusinessPartnersRequest
  ): Promise<Company> => {
    const res = await axios.post<Company>(this.url, businessPartner)
    return res.data
  }

  removeBusinessPartner = async (partnerId: string): Promise<string> => {
    await axios.delete<void>(`${this.url}/${partnerId}`)
    return partnerId
  }

  getContacts = async (partnerId: string): Promise<GetBusinessPartnerContactsResponse> => {
    const res = await axios.get<Contact[]>(`${this.url}/${partnerId}/contacts`)
    return {
      partnerId,
      data: res.data
    }
  }

  addContact = async ({ partnerId, ...contact }: AddContactRequest): Promise<Contact> => {
    const res = await axios.put<Contact>(`${this.url}/${partnerId}/contacts`, contact)
    return res.data
  }

  deleteContact = async ({
    partnerId,
    contactId
  }: DeleteContactRequest): Promise<DeleteContactRequest> => {
    await axios.delete(`${this.url}/${partnerId}/contacts/${contactId}`)
    return { partnerId, contactId }
  }

  update = async ({
    partner,
    externalId
  }: UpdateBusinessPartnerRequest): Promise<UpdateBusinessPartnerRequest> => {
    const res = await axios.put(`${this.url}/${partner.id}`, {
      partner,
      externalId
    })
    return res.data
  }

  getById = async (id: string): Promise<Company> => {
    const res = await axios.get<Company>(`${this.url}/${id}`)
    return res.data
  }
}

export default new BusinessPartnersService()
