export const INSIGHTS_STATUS_OPTIONS = [
  {
    label: 'Pending',
    value: 'Pending'
  },
  {
    label: 'Posted',
    value: 'Posted'
  },
  {
    label: 'Staged',
    value: 'Staged'
  },
  {
    label: 'Expected Issue',
    value: 'Expected Issue'
  },
  {
    label: 'Loading',
    value: 'Loading'
  },
  {
    label: 'Loaded',
    value: 'Loaded'
  }
]

export const INSIGHTS_ISSUES_OPTIONS = [
  {
    label: 'Driver Not Authorized',
    value: 'Driver Not Authorized'
  },
  {
    label: 'Expired Trailer Certs',
    value: 'Expired Trailer Certs'
  },
  {
    label: 'Carrier Invalid/Expired Insurance',
    value: 'Carrier Invalid/Expired Insurance'
  },
  {
    label: 'Insurance Nearing Expiration',
    value: 'Insurance Nearing Expiration'
  },
  {
    label: 'Driver Authorization Expiring Soon',
    value: 'Driver Authorization Expiring Soon'
  },
  {
    label: 'Trailer certification expiring soon',
    value: 'Trailer certification expiring soon'
  }
]

export const INSIGHTS_ISSUES_RED = [
  'Driver Not Authorized',
  'Expired Trailer Certs',
  'Carrier Invalid/Expired Insurance'
]
