import { createAsyncThunk } from '@reduxjs/toolkit'
import companyService from 'services/company'
import { User } from 'models'
import { accountService } from 'services/account-service'
import { authService } from 'services/auth'
import userService, { UpdateUserRequest } from 'services/user'

export const signIn = createAsyncThunk('auth/sign-in', authService.signIn)
export const signInWithMicrosoft = createAsyncThunk(
  'auth/sign-in-microsoft',
  authService.signInWithMicrosoft
)
export const refreshToken = createAsyncThunk('auth/refresh-token', authService.refreshToken)
export const getMe = createAsyncThunk('auth/me', authService.me)
export const forgotPassword = createAsyncThunk(
  'auth/forgot-password',
  accountService.requestPasswordReset
)
export const updateCompany = createAsyncThunk('company/update', companyService.update)

export const updateUser = createAsyncThunk<User, UpdateUserRequest & { file?: File }>(
  'user/update',
  async ({ file, ...user }) => {
    let pictureUrl = ''
    if (file) {
      const picture = await userService.updateUserPicture(file)
      if (picture) pictureUrl = picture.url
    }
    return userService.update({
      ...user,
      pictureUrl
    })
  }
)
