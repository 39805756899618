import { createAsyncThunk } from '@reduxjs/toolkit'
import lookupTableService from 'services/lookup-table'

export const getLookupTables = createAsyncThunk(
  'lookup-table/find',
  lookupTableService.getLookupTables
)

export const getLookupTable = createAsyncThunk(
  'lookup-table/values/find',
  lookupTableService.getLookupTable
)

export const deleteLookupValue = createAsyncThunk(
  'lookup-table/delete',
  lookupTableService.deleteLookupValue
)

export const bulkUploadLookupValues = createAsyncThunk(
  'lookup-table/bulk-upload',
  lookupTableService.bulkUploadLookupValues
)

export const editLookupTable = createAsyncThunk(
  'lookup-table/edit',
  lookupTableService.editLookupTable
)

export const createLookupTable = createAsyncThunk(
  'lookup-table/create',
  lookupTableService.createLookupTable
)

export const updateLookupTableValues = createAsyncThunk(
  'lookup-table/values/update',
  lookupTableService.updateLookupTableValues
)
