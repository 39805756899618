import { PIDXFamily } from 'models'
import axios from '../base'

export class PIDXFamilyService {
  url = 'v1/pidx-family'

  getPIDXFamilies = async (): Promise<PIDXFamily[]> => {
    const { data } = await axios.get(this.url)
    return data
  }
}

export default new PIDXFamilyService()
