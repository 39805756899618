import { Shipment } from 'models'
import { filtersActions } from '../filters'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PaginationResponse } from 'types/pagination-response'
import { shipmentService, FindShipmentsQuery, UpdateShipmentRequest } from 'services/shipment'

export const findShipments = createAsyncThunk<PaginationResponse<Shipment>, FindShipmentsQuery>(
  'shipment/find',
  (query, thunkAPI) => {
    thunkAPI.dispatch(filtersActions.setCurrentFilter(query))
    return shipmentService.find(query)
  }
)

export const findShipmentById = createAsyncThunk('shipment/id', shipmentService.findById)

export const assignDriver = createAsyncThunk(
  'shipment/id/driver/assign',
  shipmentService.assignDriver
)

export const updateShipment = createAsyncThunk<Shipment, UpdateShipmentRequest>(
  'shipment/id/update',
  async query => {
    await shipmentService.update(query)
    return await shipmentService.findById(query.id)
  }
)

export const createShipment = createAsyncThunk('shipment/id/create', shipmentService.create)

export const cloneShipment = createAsyncThunk('shipment/clone', shipmentService.clone)

export const regenerateOrderByIds = createAsyncThunk(
  'shipment/regenerate/:ids',
  shipmentService.regenerateOrderByIds
)
