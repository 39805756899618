import { LookupTableValue } from 'models'
import { LookupTable } from 'models/lookup-table.model'
import { saveFile } from 'utils/save-file'
import axios from '../base'
import {
  BulkUploadLookupValues,
  CreateLookupTableRequest,
  EditLookupTableRequest,
  UpdateLookupTableValuesRequest
} from './lookup-table.types'

export class LookupTableService {
  private url = 'v1/lookup'

  getLookupTables = async (): Promise<LookupTable[]> => {
    const { data } = await axios.get<LookupTable[]>(`${this.url}/table`)
    return data
  }

  getLookupTable = async (lookupTableId: string): Promise<LookupTable> => {
    const { data } = await axios.get<LookupTable>(`${this.url}/table/${lookupTableId}`)
    return data
  }

  createLookupTable = async ({
    atObject,
    forObject,
    name,
    returnValue
  }: CreateLookupTableRequest): Promise<LookupTable> => {
    const { data } = await axios.post<LookupTable>(`${this.url}/table`, {
      atObject,
      forObject,
      name,
      returnValue,
      lookupValues: []
    })
    return data
  }

  editLookupTable = async (newLookupTable: EditLookupTableRequest): Promise<LookupTable> => {
    const { data } = await axios.patch<LookupTable>(`${this.url}/table`, newLookupTable)
    return data
  }

  deleteLookupValue = async (id: string): Promise<string> => {
    await axios.delete<LookupTable>(`${this.url}/value/${id}`)
    return id
  }

  bulkUploadLookupValues = async ({ id, file }: BulkUploadLookupValues) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/table/${id}/bulkupload?includeSummary=true`, fd)
    return res.data
  }

  downloadLookupTemplate = async (tableId: string) => {
    const response = await axios.get(`${this.url}/table/${tableId}/bulkupload`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  updateLookupTableValues = async ({
    id,
    values
  }: UpdateLookupTableValuesRequest): Promise<LookupTableValue[]> => {
    await axios.put(`${this.url}/table/${id}/values`, values)
    const data = await this.getLookupTable(id)
    return data.lookupValues
  }
}

export default new LookupTableService()
