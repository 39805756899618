import { User } from '../../models'
import { PaginationResponse } from '../../types/pagination-response'
import axios from '../base'
import {
  FindUsersQuery,
  CreateUserRequest,
  DeleteUserRequest,
  DeleteUserResponse,
  FindUsersResponse
} from './admin-user.types'

export class AdminUserService {
  private url = 'v1/velostics-admin/users'

  getTeam = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: FindUsersQuery): Promise<FindUsersResponse> => {
    const res = await axios.get<FindUsersResponse>(`${this.url}/team`, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return res.data
  }

  deleteAdmin = async (params: DeleteUserRequest): Promise<DeleteUserResponse> => {
    await axios.delete<void>(`${this.url}/team/${params.userId}`)
    return {
      userId: params.userId
    }
  }

  createAdmin = async (body: CreateUserRequest): Promise<User> => {
    const { data } = await axios.post<User>(`${this.url}/team`, body, {
      params: { companyId: body.companyId }
    })
    return data
  }

  find = async ({
    page = 1,
    pageSize = 25,
    query,
    ...params
  }: FindUsersQuery): Promise<PaginationResponse<User>> => {
    const res = await axios.get<PaginationResponse<User>>(this.url, {
      params: {
        page,
        pageSize,
        q: query,
        ...params
      }
    })
    return res.data
  }
}

export default new AdminUserService()
