import { Company, User } from 'models'
import axios from '../base'
import {
  CreateAdminCarrierRequest,
  GetAdminCarriersQuery,
  GetAdminCarriersResponse,
  UpdateAdminCarrierRequest,
  GetAdminCarrierRequest,
  DeleteAdminCarrierRequest,
  DeleteAdminCarrierResponse,
  GetCompanyAdminsQuery,
  GetCompanyAdminsResponse,
  UpdateCompanyAdminRequest,
  DeleteAdminCompanyAdminResponse,
  DeleteAdminCompanyAdminRequest,
  CreateCompanyAdminRequest
} from './admin-carrier.types'

export class AdminCarrierService {
  private url = 'v1/velostics-admin/carriers'

  getAdminCarriers = async ({
    page = 1,
    pageSize = 15,
    query,
    shipmentId,
    include,
    orderBy,
    ...params
  }: GetAdminCarriersQuery): Promise<GetAdminCarriersResponse> => {
    const res = await axios.get<GetAdminCarriersResponse>(this.url, {
      params: {
        q: query,
        pageSize,
        page,
        shipment_id: shipmentId,
        include: include ? include.join(',') : undefined,
        orderBy: orderBy ? `company.${orderBy}` : undefined,
        ...params
      }
    })
    return res.data
  }

  getAdminCarrier = async ({ companyId }: GetAdminCarrierRequest): Promise<Company> => {
    const res = await axios.get(`${this.url}/${companyId}`)
    return res.data
  }

  createAdminCarrier = async (carrier: CreateAdminCarrierRequest): Promise<Company> => {
    const { data } = await axios.post<Company>(this.url, carrier)
    return data
  }

  updateCarrier = async (carrier: UpdateAdminCarrierRequest): Promise<Company> => {
    const { data } = await axios.put<Company>(`${this.url}/update`, carrier)
    return data
  }

  updateCompanyAdmin = async ({ user, id }: UpdateCompanyAdminRequest): Promise<User> => {
    const { data } = await axios.put<User>(`${this.url}/company-admin/${id}`, user)
    return data
  }

  deleteCarrier = async ({
    companyId
  }: DeleteAdminCarrierRequest): Promise<DeleteAdminCarrierResponse> => {
    await axios.delete<void>(`${this.url}/${companyId}`)
    return { companyId }
  }

  deleteCompanyAdmin = async ({
    userId
  }: DeleteAdminCompanyAdminRequest): Promise<DeleteAdminCompanyAdminResponse> => {
    await axios.delete<void>(`${this.url}/company-admin/${userId}`)
    return { userId }
  }

  getCompanyAdmins = async ({
    pageSize = 15,
    page = 1,
    query,
    ...params
  }: GetCompanyAdminsQuery): Promise<GetCompanyAdminsResponse> => {
    const res = await axios.get<GetCompanyAdminsResponse>(`${this.url}/company-admins`, {
      params: {
        q: query,
        pageSize,
        page,
        ...params
      }
    })
    return res.data
  }

  createCompanyAdmin = async ({ user, companyId }: CreateCompanyAdminRequest): Promise<User> => {
    const { data } = await axios.post<User>(`${this.url}/${companyId}/company-admin`, user)
    return data
  }
}

export default new AdminCarrierService()
