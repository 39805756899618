import { createSlice } from '@reduxjs/toolkit'
import { Product } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  uploadProducts
} from './product.actions'

export type ProductState = {
  products: Product[]
  productsStatus: APILoadingStatus
  productsError: Record<string, unknown> | null
  productsTotal: number
  updateStatus: APILoadingStatus
  createStatus: APILoadingStatus
  deleteStatus: APILoadingStatus
  uploadStatus: APILoadingStatus
}

const initialState: ProductState = {
  products: [],
  productsStatus: APILoadingStatus.Idle,
  productsError: null,
  productsTotal: 0,
  updateStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  deleteStatus: APILoadingStatus.Idle,
  uploadStatus: APILoadingStatus.Idle
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, uploadProducts, 'uploadStatus')
    asyncReducers(builder, deleteProduct, 'deleteStatus')
    asyncReducers(builder, createProduct, 'createStatus')
    asyncReducers(builder, updateProduct, 'updateStatus', (state, action) => {
      state.products = state.products.map(product =>
        product.id === action.payload.id ? action.payload : product
      )
    })
    asyncReducers(builder, getProducts, 'productsStatus', (state, action) => {
      state.products = action.payload.results
      state.productsTotal = action.payload.total
    })
  }
})

export default productSlice.reducer
export const productActions = {
  ...productSlice.actions,
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  uploadProducts
}
