import { createAsyncThunk } from '@reduxjs/toolkit'
import productCarrierMappingService from 'services/product-carrier-mapping'

export const getProductCarrierMappings = createAsyncThunk(
  'product-carrier-mapping/find',
  productCarrierMappingService.getProductCarrierMappings
)

export const createProductCarrierMapping = createAsyncThunk(
  'product-carrier-mapping/create',
  productCarrierMappingService.createProductCarrierMapping
)

export const updateProductCarrierMapping = createAsyncThunk(
  'product-carrier-mapping/update',
  productCarrierMappingService.updateProductCarrierMapping
)

export const deleteProductCarrierMappings = createAsyncThunk(
  'product-carrier-mapping/delete/:ids',
  productCarrierMappingService.deleteProductCarrierMappings
)

export const deleteProductCarrierMapping = createAsyncThunk(
  'product-carrier-mapping/delete/:id',
  productCarrierMappingService.deleteProductCarrierMapping
)
