import { createSlice } from '@reduxjs/toolkit'
import { Shipment } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import {
  findShipments,
  findShipmentById,
  assignDriver,
  updateShipment,
  createShipment,
  cloneShipment,
  regenerateOrderByIds
} from './shipment.actions'

export type ShipmentState = {
  shipments: Shipment[]
  shipmentsStatus: APILoadingStatus
  shipmentsError: Record<string, unknown> | null
  shipmentsTotal: number
  shipment: Shipment | null
  shipmentStatus: APILoadingStatus
  shipmentError: Record<string, unknown> | null
  updateShipmentStatus: APILoadingStatus
  createShipmentStatus: APILoadingStatus
  cloneShipmentStatus: APILoadingStatus
  assignDriverStatus: APILoadingStatus
  regenerateStatus: APILoadingStatus
}

const initialState: ShipmentState = {
  shipments: [],
  shipmentsStatus: APILoadingStatus.Idle,
  shipmentsError: null,
  shipmentsTotal: 1,
  shipment: null,
  shipmentStatus: APILoadingStatus.Idle,
  shipmentError: null,
  updateShipmentStatus: APILoadingStatus.Idle,
  createShipmentStatus: APILoadingStatus.Idle,
  cloneShipmentStatus: APILoadingStatus.Idle,
  assignDriverStatus: APILoadingStatus.Idle,
  regenerateStatus: APILoadingStatus.Idle
}

const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(
      builder,
      findShipmentById,
      'shipmentStatus',
      (state, action) => {
        const shipment = action.payload
        state.shipment = shipment
      }
    )
    asyncReducers(
      builder,
      findShipments,
      'shipmentsStatus',
      (state, action) => {
        state.shipmentsTotal = action.payload.total
        state.shipments = action.payload.results
      }
    )
    asyncReducers(builder, assignDriver, 'assignDriverStatus')
    asyncReducers(
      builder,
      updateShipment,
      'updateShipmentStatus',
      (state, action) => {
        state.shipment = action.payload
      }
    )
    asyncReducers(
      builder,
      createShipment,
      'createShipmentStatus',
      (state, action) => {
        state.shipment = action.payload
      }
    )
    asyncReducers(
      builder,
      cloneShipment,
      'cloneShipmentStatus',
      (state, action) => {
        if (action.payload.length > 0) {
          state.shipment = action.payload[0]
        }
      }
    )
    asyncReducers(
      builder,
      regenerateOrderByIds,
      'regenerateStatus'
      // 'cloneShipmentStatus',
      // (state, action) => {

      // }
    )
  }
})

export default shipmentSlice.reducer
export const shipmentActions = {
  ...shipmentSlice.actions,
  findShipmentById,
  findShipments,
  assignDriver,
  updateShipment,
  createShipment,
  regenerateOrderByIds,
  cloneShipment
}
