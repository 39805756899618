import { Flex, Spinner } from '@velostics/react-components'

export const PageLoader = () => (
  <Flex
    align="center"
    justify="center"
    style={{
      height: '100vh',
      backgroundColor: 'white'
    }}>
    <Spinner size="large" />
  </Flex>
)
