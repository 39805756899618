import { createAsyncThunk } from '@reduxjs/toolkit'
import qrFormatService from 'services/qr-format'
import qrTemplateService from 'services/qr-template'

export const getAllQRTemplates = createAsyncThunk(
  'pass-config/template/all',
  qrTemplateService.getAllQRTemplates
)

export const getQRTemplate = createAsyncThunk(
  'pass-config/template/id',
  qrTemplateService.getQRTemplate
)

export const getAllQRFormats = createAsyncThunk(
  'pass-config/format/all',
  qrFormatService.getAllQRFormats
)

export const getQRFormat = createAsyncThunk(
  'pass-config/format/:id',
  qrFormatService.getQRFormat
)

export const updateQRTemplate = createAsyncThunk(
  'pass-config/template/update/:id',
  qrTemplateService.updateQRTemplate
)

export const createQRTemplate = createAsyncThunk(
  'pass-config/template/create',
  qrTemplateService.createQRTemplate
)

export const deleteQRTemplate = createAsyncThunk(
  'pass-config/template/delete',
  qrTemplateService.deleteQRTemplate
)
