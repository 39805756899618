import { createAsyncThunk } from '@reduxjs/toolkit'
import adminCarrierService from 'services/admin-carrier'

export const getCarriers = createAsyncThunk(
  'admin-carrier/find',
  adminCarrierService.getAdminCarriers
)

export const getCarrier = createAsyncThunk(
  'admin-carrier/find-carrier',
  adminCarrierService.getAdminCarrier
)

export const createAdminCarrier = createAsyncThunk(
  'admin-carrier/create',
  adminCarrierService.createAdminCarrier
)

export const updateCarrier = createAsyncThunk(
  'admin-carrier/:id/update',
  adminCarrierService.updateCarrier
)

export const deleteCarrier = createAsyncThunk(
  'admin-carrier/:id/delete',
  adminCarrierService.deleteCarrier
)

export const deleteCompanyAdmin = createAsyncThunk(
  'admin-carrier/:id/delete-admin',
  adminCarrierService.deleteCompanyAdmin
)

export const getCompanyAdmins = createAsyncThunk(
  'admin-carrier/company-admins',
  adminCarrierService.getCompanyAdmins
)

export const updateCompanyAdmin = createAsyncThunk(
  'admin-carrier/update/company-admin',
  adminCarrierService.updateCompanyAdmin
)

export const createCompanyAdmin = createAsyncThunk(
  'admin-carrier/create/company-admin',
  adminCarrierService.createCompanyAdmin
)
