import { combineReducers } from '@reduxjs/toolkit'
import team from './team'
import adminUser from './admin-user'
import adminCarrier from './admin-carrier'
import adminCompany from './admin-company'

const adminReducer = combineReducers({
  team,
  user: adminUser,
  company: adminCompany,
  carrier: adminCarrier
})

export type AdminState = ReturnType<typeof adminReducer>
export default adminReducer
