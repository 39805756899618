import { createSlice } from '@reduxjs/toolkit'
import { User } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { findUsers, deleteUser, resendInvitation, updateUser, createUser } from './user.actions'

export type UserState = {
  users: User[]
  usersStatus: APILoadingStatus
  usersError: Record<string, unknown> | null
  usersTotal: number
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: UserState = {
  users: [],
  usersStatus: APILoadingStatus.Idle,
  usersError: null,
  usersTotal: 1,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, resendInvitation)
    asyncReducers(builder, deleteUser, 'usersStatus')
    asyncReducers(builder, createUser, 'createStatus')
    asyncReducers(builder, findUsers, 'usersStatus', (state, action) => {
      state.users = action.payload.results
      state.usersTotal = action.payload.total
    })
    asyncReducers(builder, updateUser, 'updateStatus', (state, action) => {
      state.users = state.users.map(user => (user.id === action.payload.id ? action.payload : user))
    })
  }
})

export default userSlice.reducer
export const userActions = {
  ...userSlice.actions,
  findUsers,
  deleteUser,
  resendInvitation,
  updateUser,
  createUser
}
