// import { PaginationResponse } from 'types/pagination-response'
import { Trailer } from 'models'
import axios from '../base'
import {
  GetAllTrailersRequest,
  GetAllTrailersResponse,
  CreateTrailerRequest,
  UpdateTrailerRequest,
  DeleteTrailerRequest,
  DeleteTrailerResponse
} from './trailer.types'

export class TrailerService {
  private url = 'v1/trailers'

  getAll = async ({ query, ...params }: GetAllTrailersRequest): Promise<GetAllTrailersResponse> => {
    const res = await axios.get(this.url, {
      params: {
        q: query,
        ...params
      }
    })
    return res.data
  }

  deleteTrailer = async (params: DeleteTrailerRequest): Promise<DeleteTrailerResponse> => {
    const { data } = await axios.delete<void>(`${this.url}/${params.id}`)
    console.log(data)

    return {
      id: params.id
    }
  }

  updateTrailer = async (body: UpdateTrailerRequest): Promise<Trailer> => {
    const { data } = await axios.patch<Trailer>(`${this.url}/${body.id}`, body)
    return data
  }

  create = async (body: CreateTrailerRequest): Promise<Trailer> => {
    const { data } = await axios.post<Trailer>(this.url, body)
    return data
  }
}

export default new TrailerService()
