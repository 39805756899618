import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { init as SentryInit } from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
// Initialize i18n
import './i18n'

SentryInit({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.NODE_ENV,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SAMPLE_RATE)
    : 0.2,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
