import { createSlice } from '@reduxjs/toolkit'
import { Location } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  findLocations,
  updateLocation,
  bulkUpload,
  deleteLocation,
  createLocation
} from './location.actions'

export type LocationState = {
  locations: Location[]
  locationsStatus: APILoadingStatus
  locationsTotal: number
  locationsError: Record<string, unknown> | null
  bulkUploadStatus: APILoadingStatus
  updateStatus: APILoadingStatus
}

const initialState: LocationState = {
  locations: [],
  locationsTotal: 1,
  locationsStatus: APILoadingStatus.Idle,
  locationsError: null,
  updateStatus: APILoadingStatus.Idle,
  bulkUploadStatus: APILoadingStatus.Idle
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, updateLocation, 'locationsStatus')
    asyncReducers(builder, bulkUpload, 'bulkUploadStatus')
    asyncReducers(
      builder,
      findLocations,
      'locationsStatus',
      (state, action) => {
        state.locations = action.payload.results
        state.locationsTotal = action.payload.total
      }
    )
    asyncReducers(builder, deleteLocation, 'locationsStatus')
    asyncReducers(builder, createLocation, 'locationsStatus')
  }
})

export default locationSlice.reducer
export const locationActions = {
  ...locationSlice.actions,
  updateLocation,
  findLocations,
  bulkUpload,
  deleteLocation,
  createLocation
}
