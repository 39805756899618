import { Company } from 'models'
import { saveFile } from 'utils/save-file'
import axios from '../base'
import {
  CreateOrRelateCarrierRequest,
  FindCarriersQuery,
  FindCarriersResponse,
  UpdateCarrierRequest
} from './carrier.types'

export class CarrierService {
  private url = 'v1/carriers'

  downloadBulkUploadTemplate = async () => {
    const response = await axios.get(`${this.url}/bulkupload/template`, {
      responseType: 'arraybuffer'
    })
    saveFile(response)
  }

  bulkUpload = async (file: File) => {
    const fd = new FormData()
    fd.append('file', file)
    const res = await axios.post(`${this.url}/bulkupload`, fd)
    return res.data
  }

  getCarriers = async ({
    page = 1,
    pageSize = 15,
    query,
    shipmentId,
    include,
    orderBy,
    ...params
  }: FindCarriersQuery): Promise<FindCarriersResponse> => {
    const res = await axios.get<FindCarriersResponse>(this.url, {
      params: {
        q: query,
        pageSize,
        page,
        shipment_id: shipmentId,
        include: include ? include.join(',') : undefined,
        orderBy: orderBy ? `company.${orderBy}` : undefined,
        ...params
      }
    })
    return res.data
  }

  getCarriersIntegration = async ({
    page = 1,
    pageSize = 15,
    query,
    ...params
  }: FindCarriersQuery): Promise<FindCarriersResponse> => {
    const res = await axios.get<FindCarriersResponse>(`${this.url}/integrations`, {
      params: {
        q: query,
        pageSize,
        page,
        ...params
      }
    })
    return res.data
  }

  createOrRelateCarrier = async (carrier: CreateOrRelateCarrierRequest): Promise<Company> => {
    const { data } = await axios.post<Company>(this.url, carrier)
    return data
  }

  updateCarrier = async (carrier: UpdateCarrierRequest): Promise<Company> => {
    const { data } = await axios.put<Company>(this.url, carrier)
    return data
  }

  unrelateCarrier = async (id: string): Promise<string> => {
    await axios.delete<Company>(`${this.url}/${id}`)
    return id
  }
}

export default new CarrierService()
