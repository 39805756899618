import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from 'models'
import { accountService } from 'services/account-service'
import { PaginationResponse } from 'types/pagination-response'
import userService, { FindUsersQuery } from 'services/user'
import { RootState } from '../..'

export const findUsers = createAsyncThunk<PaginationResponse<User>, FindUsersQuery>(
  'user/findUser',
  (query, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    return userService.find({
      ...query,
      companyId: query.companyId || state.auth.user?.company?.id
    })
  }
)
export const deleteUser = createAsyncThunk('users/deleteUser', userService.delete)
export const updateUser = createAsyncThunk('users/:id/update', userService.updateUser)
export const createUser = createAsyncThunk('users/create', userService.createUser)
export const resendInvitation = createAsyncThunk(
  'users/resendInvitation',
  accountService.resendInvitation
)
