import { createSlice } from '@reduxjs/toolkit'
import { Location } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import { APIState } from 'types/api-state'
import { PaginationResponse } from 'types/pagination-response'
import createAsyncReducers from 'utils/async-reducers'
import {
  bulkUpload,
  findLocationsByPartner,
  createAndAssociateLocation,
  updateLocation,
  unrelateLocation
} from './business-partner-locations.actions'

export type BusinessPartnerLocationsState = {
  locations: APIState<Record<string, PaginationResponse<Location>>>
  bulkUploadStatus: APILoadingStatus
  createStatus: APILoadingStatus
  updateStatus: APILoadingStatus
  unrelateStatus: APILoadingStatus
}

const initialState: BusinessPartnerLocationsState = {
  locations: {
    data: {},
    status: APILoadingStatus.Idle,
    total: 0
  },
  bulkUploadStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  updateStatus: APILoadingStatus.Idle,
  unrelateStatus: APILoadingStatus.Idle
}

const businessPartnerLocationsSlice = createSlice({
  name: 'business-partner-locations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createAsyncReducers(builder, unrelateLocation, 'unrelateStatus', (state, action) => {
      state.locations.data[action.payload.partnerId].results = state.locations.data[
        action.payload.partnerId
      ].results.filter(location => location.id !== action.payload.locationId)
    })
    createAsyncReducers(builder, bulkUpload, 'bulkUploadStatus')
    createAsyncReducers(builder, updateLocation, 'updateStatus', (state, action) => {
      const location = action.payload
      const partnerId = location.companyId
      if (partnerId && state.locations.data[partnerId]) {
        state.locations.data[partnerId].results = state.locations.data[
          partnerId
        ].results.map(curr => (curr.id === location.id ? location : curr))
      }
    })
    createAsyncReducers(builder, createAndAssociateLocation, 'createStatus')
    createAsyncReducers(
      builder,
      findLocationsByPartner,
      (state, status) => (state.locations.status = status),
      (state, action) => {
        state.locations.total = action.payload.total
        state.locations.data[action.payload.partnerId] = action.payload
      }
    )
  }
})

export default businessPartnerLocationsSlice.reducer
export const businessPartnerLocationsActions = {
  ...businessPartnerLocationsSlice.actions,
  createAndAssociateLocation,
  findLocationsByPartner,
  unrelateLocation,
  updateLocation,
  bulkUpload
}
