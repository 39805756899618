import { createSlice } from '@reduxjs/toolkit'
import { User } from 'models'
import { APILoadingStatus } from 'types/api-loading-status'
import asyncReducers from 'utils/async-reducers'
import { getTeam, deleteTeamMember, createTeamMember } from './team.actions'

export type TeamState = {
  admins: User[]
  adminsStatus: APILoadingStatus
  adminsError: Record<string, unknown> | null
  adminsTotal: number
  createStatus: APILoadingStatus
}

const initialState: TeamState = {
  admins: [],
  adminsStatus: APILoadingStatus.Idle,
  adminsError: null,
  adminsTotal: 1,
  createStatus: APILoadingStatus.Idle
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, getTeam, 'adminsStatus', (state, action) => {
      state.admins = action.payload.results
      state.adminsTotal = action.payload.total
    })
    asyncReducers(builder, deleteTeamMember, 'adminsStatus')
    asyncReducers(builder, createTeamMember, 'createStatus')
  }
})

export default teamSlice.reducer
export const teamActions = {
  ...teamSlice.actions,
  getTeam,
  deleteTeamMember,
  createTeamMember
}
