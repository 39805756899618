import { createSlice } from '@reduxjs/toolkit'
import { ReportLink } from '../../../models'
import { APILoadingStatus } from '../../../types/api-loading-status'
import asyncReducers from '../../../utils/async-reducers'
import {
  getAllAnalytics,
  createReportLink,
  updateReportLink,
  deleteReportLink,
  getReportLinkById
} from './analytics.actions'

export type AnalyticsState = {
  analytics: ReportLink[]
  selectedReportLink?: ReportLink
  analyticsStatus: APILoadingStatus
  analyticsError: Record<string, unknown> | null
  updateStatus: APILoadingStatus
  createStatus: APILoadingStatus
  deleteStatus: APILoadingStatus
}

const initialState: AnalyticsState = {
  analytics: [],
  selectedReportLink: undefined,
  analyticsStatus: APILoadingStatus.Idle,
  analyticsError: null,
  updateStatus: APILoadingStatus.Idle,
  createStatus: APILoadingStatus.Idle,
  deleteStatus: APILoadingStatus.Idle
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncReducers(builder, deleteReportLink, 'deleteStatus', (state, action) => {
      state.analytics = state.analytics.filter(
        (analytic: ReportLink) => analytic.id !== state.selectedReportLink?.id
      )
    })
    asyncReducers(builder, createReportLink, 'createStatus', (state, action) => {
      state.analytics = [action.payload, ...state.analytics]
    })
    asyncReducers(builder, updateReportLink, 'updateStatus', (state, action) => {
      state.selectedReportLink = action.payload
      state.analytics = state.analytics.map(reportLink =>
        reportLink.id === action.payload.id ? action.payload : reportLink
      )
    })
    asyncReducers(builder, getAllAnalytics, 'analyticsStatus', (state, action) => {
      state.analytics = action.payload
    })
    asyncReducers(builder, getReportLinkById, 'analyticsStatus', (state, action) => {
      state.selectedReportLink = action.payload
    })
  }
})

export default analyticsSlice.reducer
export const analyticsActions = {
  ...analyticsSlice.actions,
  getAllAnalytics,
  createReportLink,
  updateReportLink,
  deleteReportLink,
  getReportLinkById
}
