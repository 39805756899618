import { createAsyncThunk } from '@reduxjs/toolkit'
import { User } from 'models'
import { PaginationResponse } from 'types/pagination-response'
import adminUserService from '../../../services/admin-user'
import { FindUsersQuery } from '../../../services/user'

export const findUsers = createAsyncThunk<PaginationResponse<User>, FindUsersQuery>(
  'admin-user/findUser',
  query => {
    return adminUserService.find(query)
  }
)
